import axios from "axios";
import _cloneDeep from "lodash/cloneDeep";
import _maxBy from "lodash/maxBy";
import _minBy from "lodash/minBy";
import _find from "lodash/find";
import _filter from "lodash/filter";
import _groupBy from "lodash/groupBy";
import {FilterTagMapping} from "../utils/filters";
//The search module handles searching, listings and filters
export const blankUserFilter = {
  sort: undefined,
  Make: [],
  Model: [],
  Year: [],
}
export default {
  state: {
    listings: null,
    listingsLoading: null,
    listingsFailed: null,
    listing: null,
    filters: null,
    userFilter: _cloneDeep(blankUserFilter),
    searchResult: "",
    error: null,
    destinationCountry: null,
    searchLoading: false,
    sortOrder: null,
    wishList: [],
    reservationList: null,
    orderList: null,
    pendingSalesList: null,
    confirmedSalesList: null,
    archivedList: null,
    myListingsList: null,
    buyerDashboardCard: null,
    buyerMenuNumber: null,
    sellerDashboardCard: null,
    sellerMenuNumber: null,
    listingHistoryQuestions: null,
    searchLastPosition: 0,
    searchLastUrl: null,
    searchCountAll: null,
    searchPageNo: 0,
    searchPageSize: 24,

  },
  getters: {
    searchResult: state => {
      return state.searchResult;
    },
    destinationCountry: state => {
      return state.destinationCountry;
    },
    destinationCountryCode: state => {
      return state.destinationCountry.Code
        ? state.destinationCountry.Code
        : "CA";
    },
    listings: state => {
      return state.listings;
    },
    listing: state => {
      return state.listing;
    },
    listingHistoryQuestions: state => {
      return state.listingHistoryQuestions;
    },
    listingFees(state) {
      if (state.listing) {
        let fees = state.listing.Fees;
        return fees;
      } else return null;
    },
    filters: state => {
      return state.filters;
    },
    wishList: state => {
      return state.wishList;
    },
    reservationList: state => {
      return state.reservationList;
    },
    orderList: state => {
      return state.orderList;
    },
    pendingSalesList: state => {
      return state.pendingSalesList;
    },
    confirmedSalesList: state => {
      return state.confirmedSalesList;
    },
    archivedList: state => {
      return state.archivedList;
    },
    myListingsList: state => {
      return state.myListingsList;
    },
    buyerDashboardCard: state => {
      return state.buyerDashboardCard;
    },
    buyerMenuNumber: state => {
      return state.buyerMenuNumber;
    },
    sellerDashboardCard: state => {
      return state.sellerDashboardCard;
    },
    sellerMenuNumber: state => {
      return state.sellerMenuNumber;
    },
    getUserFilters: state => {
      return state.userFilter;
    },
    getUserFilterList: (state, getters) => (
      TagName,
      bindWithParent = false,
      bindWithParentName = false,
      bindWithFilterBy = false
    ) => {
      if (state.filters) {
        TagName = FilterTagMapping[TagName]
        let list = _find(state.filters, {
          TagId: TagName
        });
        if (bindWithParent && list && list.Values) {
          list = _filter(list.Values, item =>
            bindWithParent.includes(item.ParentTagValueId)
          );
          list = _groupBy(list, "ParentTagValueId");
          if (bindWithParentName) {
            const BindNameList = getters.getUserFilterList(bindWithParentName);
            const newList = {};
            Object.keys(list).forEach(key => {
              const ParentTagValue = _find(BindNameList, {
                TagValueId: parseInt(key)
              });
              if (ParentTagValue) newList[ParentTagValue.Value] = list[key];
            });
            if (bindWithFilterBy) {
              return newList.filter(item =>
                bindWithFilterBy.includes(item.TagValueId)
              );
            }
            return newList;
          }
          return list;
        }
        if (bindWithFilterBy) {
          return list.Values.filter(item =>
            bindWithFilterBy.includes(item.TagValueId)
          );
        }
        if (list) {
          return list.Values;
        }
      }
      return [];
    },
    getUserFiltersByType: state => type => {
      return state.userFilter[type];
    },
    getUserFilterValToLabel: state => (TagName, Values) => {
      const TagId = FilterTagMapping[TagName];
      let list = _find(state.filters, { TagId });
      if (!list) return [];
      return _filter(list.Values, item => Values.includes(item.TagValueId)).map(
        item => item.Value
      );
    },
    getUserFilterLabelToVal: state => (TagName, Values) => {
      const TagId = FilterTagMapping[TagName];
      let list = _find(state.filters, { TagId });
      if (!list) return [];
      return _filter(list.Values, item => Values.includes(item.Value)).map(
        item => item.TagValueId
      );
    },
    getYearMax(state, getters) {
      if (state.filters) {
        const years = getters.getUserFilterList("Year");
        const max = _maxBy(years, "Value");
        return max.Value;
      }
      return 0;
    },
    getYearMin(state, getters) {
      if (state.filters) {
        const years = getters.getUserFilterList("Year");
        const min = _minBy(years, "Value");
        return min.Value;
      }
      return 0;
    },
    isUserFiltered(state) {
      return Object.keys(state.userFilter)
        .filter(key => key !== "sort")
        .some(key => state.userFilter[key.toLowerCase()] && state.userFilter[key.toLowerCase()].length > 0);
    },
    lastUrl: (state) => state.searchLastUrl,
    lastPosition: (state) => state.searchLastPosition,
    CountAll: (state) => state.searchCountAll,
    PageNo: (state) => state.searchPageNo,
    PageSize: (state) => state.searchPageSize
  },
  actions: {
    searchResult(context, searchResult) {
      context.commit("searchResult", searchResult);
    },
    destinationCountry(context, destinationCountry) {
      context.commit("destinationCountry", destinationCountry);
    },
    destCountryChangeLang(context) {
      //Matches the current destination country with its counterpart when the language changes
      let current = context.getters.destinationCountry;
      let countries = context.getters.activeBuyerCountries;
      if (current) {
        current = countries.find(el => el.Code == current.Code);
        context.commit("destinationCountry", current);
      }
    },
    setListings({ commit }, listings) {
      commit("listings", listings);
    },
    setListing({ commit }, listing) {
      commit("listing", listing);
    },
    listingHistoryQuestions({ commit }, lhs) {
      commit("listingHistoryQuestions", lhs);
    },
    setFilters({ commit }, filters) {
      commit("filters", filters);
    },
    wishList({ commit }, value) {
      commit("wishList", value);
    },
    reservationList({ commit }, value) {
      commit("reservationList", value);
    },
    orderList({ commit }, value) {
      commit("orderList", value);
    },
    pendingSalesList({ commit }, value) {
      commit("pendingSalesList", value);
    },
    confirmedSalesList({ commit }, value) {
      commit("confirmedSalesList", value);
    },
    archivedList({ commit }, value) {
      commit("archivedList", value);
    },
    myListingsList({ commit }, value) {
      commit("myListingsList", value);
    },
    buyerDashboardCard({ commit }, value) {
      commit("buyerDashboardCard", value);
    },
    buyerMenuNumber({ commit }, value) {
      commit("buyerMenuNumber", value);
    },
    sellerDashboardCard({ commit }, value) {
      commit("sellerDashboardCard", value);
    },
    sellerMenuNumber({ commit }, value) {
      commit("sellerMenuNumber", value);
    },
    async getListings(
      { commit, dispatch, state },
      payload = {
        country: this.$store.getters.destinationCountryCode,
        PageSize: 24,
        PageNo: 1,
        Prefixes: "",
        Tags: null,
        Sort: null,
        loadMore: false
      }
    ) {
      try {
        const response = await axios.post("/Listing/ListingSearch", {
          DeliveryCountryCode: payload.country
            ? payload.country
            : state.destinationCountry.Code,
          PageSize: payload.PageSize ? payload.PageSize : 24,
          PageNo: payload.PageNo ? payload.PageNo : 1,
          Prefixes: payload.Prefixes ? payload.Prefixes.split(" ") : null,
          Tags: payload.Tags ? payload.Tags : null,
          Sort: payload.Sort ? payload.Sort : 0
        });
        commit("listings", payload.loadMore ? [...state.listings, ...response.data.Cards] : response.data.Cards);
        commit("setCountAll", response.data.CardsCount);
        commit("setPageNo", payload.PageNo);
        commit("setPageSize", payload.PageSize);
        dispatch("createFilters", response.data.Filters);
        return response.data;
      } catch (error) {
        this.errorMessage = error;
      }
    },
    setPageAttr({commit},payload){
      commit("setLastUrl", payload.LastUrl);
    },
    async getListingsLite(
      { state },
      payload = {
        country: this.$store.getters.destinationCountryCode,
        Tags: null
      }
    ) {
      try {
        const response = await axios.post("/Listing/ListingSearchLite", {
          DeliveryCountryCode: payload.country
            ? payload.country
            : state.destinationCountry.Code,

          Tags: payload.Tags ? payload.Tags : null
        });
        return response.data;
      } catch (error) {
        this.errorMessage = error;
      }
    },
    async getListingsFiltered({ state }, payload = { country: undefined }) {
      try {
        const response = await axios.post("/Listing/FilterList", {
          DeliveryCountryCode: payload.country
            ? payload.country
            : state.destinationCountry.Code
        });
        state.filters = response.data.Filters;
        return response.data;
      } catch (error) {
        this.errorMessage = error;
      }
    },
    async clearListing({ commit }) {
      commit("clearListing");
    },
    async getListing({ commit, getters }, { id }) {
      try {
        const response = await axios.post("Listing/ListingData", {
          ListingId: id,
          DeliveryCountryCode: getters.destinationCountry
            ? getters.destinationCountry.Code
            : "CM"
        });
        let r = response.data;
        // hq = JSON.parse(response.data.result).Table1;
        commit("listing", r);
        // commit("listingHistoryQuestions", hq);
        return r;
      } catch (error) {
        this.errorMessage = error;
      }
    },
    createFilters({ commit }, filters) {
      //Parse the object from API for the filters
      // filters.forEach(el => {
      //   if (el.Category) {
      //     el.Category = JSON.parse(el.Category);
      //   }
      //   if (el.Values) {
      //     el.Values = JSON.parse(el.Values);
      //   }
      // });
      commit("filters", filters);
    },
    async updateWishList({ getters, dispatch, state, commit }, listingId) {
      try {
        await axios.post("listing/WishList", {
          listingId,
          DeliveryCountryCode: state.destinationCountry.Code ? state.destinationCountry.Code : 'CM'
        });
        if(state.listing && state.listing.Cardsdata){
          const listing = state.listing;
          listing.Cardsdata.IsWishList = !listing.Cardsdata.IsWishList;
          commit('listing', listing)
        }
        dispatch("getWishList", getters.userID);
      } catch (error) {
        this.errorMessage = error;
        throw error
      }
    },
    async getWishList({ commit }) {
      try {

        const response = await axios.get("reservation/GetWishList");
        commit("wishList", response.data);
      } catch (error) {
        this.errorMessage = error;
      }
    },
    async getReservationList({ commit }, params) {
      try {
        const response = await axios.post("Reservation/BuyerDashboardPages", {
          ...params
        });
        commit("reservationList", response.data);
      } catch (error) {
        this.errorMessage = error;
      }
    },
    async getOrderList({ getters, commit }, value) {
      try {
        const response = await axios.post(
          "sp/post/sp_BuyerDashboardPages_Get",
          {
            UpdatedBy: getters.userID,
            LanguageCode: value.lang,
            Status: value.status,
            PurchacedDate: value.purchacedDate,
            BuyerDashboardSection: "Orders"
          }
        );
        commit("orderList", JSON.parse(response.data.result));
      } catch (error) {
        this.errorMessage = error;
      }
    },
    async getPendingSalesList({ commit }, value) {
      try {
        const response = await axios.post("Listing/SellerDashboardPages", {
          SelectedDateRange: value.date,
          ListingStatus: ["4"]
        });
        await commit("pendingSalesList", response.data);
      } catch (error) {
        this.errorMessage = error;
      }
    },
    async getConfirmedSalesList({ commit }, value) {
      try {
        const response = await axios.post("Listing/SellerDashboardPages", {
          SelectedDateRange: value.date,
          ListingStatus: ["5"]
        });
        await commit("confirmedSalesList", response.data);
      } catch (error) {
        this.errorMessage = error;
      }
    },
    async getArchivedList({ commit }, value) {
      try {
        const status = ["2", "6", "7"];
        const response = await axios.post("Listing/SellerDashboardPages", {
          SelectedDateRange: value.date,
          ListingStatus: value.status.includes(-1) ? status : value.status
        });
        await commit("archivedList", response.data);
      } catch (error) {
        this.errorMessage = error;
      }
    },
    async getMyListingsList({ commit }, value) {
      try {
        const status = ["0", "1", "3"];
        const response = await axios.post("Listing/SellerDashboardPages", {
          SelectedDateRange: value.date,
          ListingStatus: value.status.includes(-1) ? status : value.status
        });
        await commit("myListingsList", response.data);
      } catch (error) {
        this.errorMessage = error;
      }
    },
    async getBuyerInfo({ commit }, value) {
      try {
        // const response = await axios.post("SP/post/sp_BuyerDashboard_Get", {
        //   UpdatedBy: getters.userID,
        //   languageCode: value.lang,
        //   ReservedStatus: value.status,
        //   PurchacedDate: value.purchacedDate
        // });
        const response = await axios.post("Reservation/BuyerDashboard", {
          SelectedDateRange: value.date,
          ReservationStatus: value.status
        });
        commit("buyerDashboardCard", response.data.Property1);
      } catch (error) {
        this.errorMessage = error;
      }
    },
    async getSellerInfo({ commit }, value) {
      try {
        // const response = await axios.post("SP/post/sp_SellerDashboard_Get", {
        //   UpdatedBy: getters.userID,
        //   languageCode: value.lang,
        //   PurchacedDate: value.purchacedDate
        // });
        const response = await axios.post("Listing/SellerDashboard", {
          PurchacedDate: value
        });
        commit("sellerDashboardCard", response.data.Property1);
      } catch (error) {
        this.errorMessage = error;
      }
    },
    searchLoading({ commit }, searchLoading) {
      commit("searchLoading", searchLoading);
    },
    sortOrder({ commit }, sortOrder) {
      commit("sortOrder", sortOrder);
    },
    setUserFilter({ commit }, filter) {
      commit("setUserFilters", filter);
    },
    setUserAllFilter({ commit }, filter) {
      commit("setUserAllFilters", filter);
    },
    clearUserFilters({ commit }) {
      commit("clearUserFilters");
    },
    removeItemUserFilters({ commit }, payload = { type: "", item: {} }) {
      debugger
      commit("removeItemUserFilters", payload);
    }
  },
  mutations: {
    searchResult(state, searchResult) {
      state.searchResult = searchResult;
    },
    destinationCountry(state, destinationCountry) {
      state.destinationCountry = destinationCountry;
    },
    listings(state, listings) {
      state.listings = listings;
    },
    listing(state, listing) {
      state.listing = listing;
    },
    clearListing(state) {
      state.listing = null;
    },
    listingHistoryQuestions(state, lhs) {
      state.listingHistoryQuestions = lhs;
    },
    filters(state, filters) {
      state.filters = filters;
    },
    searchLoading(state, searchLoading) {
      state.searchLoading = searchLoading;
    },
    sortOrder(state, sortOrder) {
      state.sortOrder = sortOrder;
    },
    wishList(state, value) {
      state.wishList = value;
    },
    reservationList(state, value) {
      state.reservationList = value;
    },
    orderList(state, value) {
      state.orderList = value;
    },
    pendingSalesList(state, value) {
      state.pendingSalesList = value;
    },
    confirmedSalesList(state, value) {
      state.confirmedSalesList = value;
    },
    archivedList(state, value) {
      state.archivedList = value;
    },
    myListingsList(state, value) {
      state.myListingsList = value;
    },
    buyerDashboardCard(state, value) {
      state.buyerDashboardCard = value;
    },
    buyerMenuNumber(state, value) {
      state.buyerMenuNumber = value;
    },
    sellerDashboardCard(state, value) {
      state.sellerDashboardCard = value;
    },
    sellerMenuNumber(state, value) {
      state.sellerMenuNumber = value;
    },
    setUserFilters(state, filter) {
      const userFilter = _cloneDeep(state.userFilter);
      userFilter[filter.type] = filter.selected;
      state.userFilter = userFilter;
    },
    setUserAllFilters(state, filter) {
      state.userFilter = JSON.parse(JSON.stringify(filter));
    },
    clearUserFilters(state) {
      state.userFilter = JSON.parse(JSON.stringify({
        Make: [],
        Model: [],
        Year: [],
        sort: undefined
      }));
    },
    removeItemUserFilters(
      state,
      payload = { type: "", item: "", getters: () => {} }
    ) {
      const userFilter = _cloneDeep(state.userFilter);
      if (payload.type === "Year") {
        userFilter[payload.type] = [];
      } else {
        userFilter[payload.type] = _filter(
          userFilter[payload.type],
          item => item !== payload.item.TagValueId
        );
      }
      state.userFilter = { ...userFilter };
    },
    setLastPosition(state, position) {
      state.searchLastPosition = position;
    },
    setLastUrl(state, LastUrl) {
      state.searchLastUrl = LastUrl;
    },
    setCountAll(state, CountAll) {
      state.searchCountAll = CountAll;
    },
    setPageNo(state, PageNo) {
      state.searchPageNo = PageNo;
    },
    setPageSize(state, PageSize) {
      state.searchPageSize = PageSize;
    },

  }
};
