<template>
  <b-modal
    scrollable
    centered
    :id="id"
    content-class="choose-country-content"
    hide-footer
    @hidden="onCloseModal"
  >
    <template #modal-header>
      <div class="w-100">
        <div class="d-flex justify-content-between">
          <b>
            {{ $t("Modal.SelectCounty.title") }}
          </b>
          <div @click="$bvModal.hide(id)" class="cursor-pointer">
            <b-icon icon="x" scale="2" />
          </div>
        </div>
        <b-form-input
          v-model="searchCountry"
          :placeholder="$t('Modal.SelectCounty.placeholder')"
          class="w-100 my-2"
        />
      </div>
    </template>

    <b-list-group>
      <b-list-group-item
        v-for="country in countries.filter(
          item => item.Name.toLowerCase().indexOf(searchCountry) !== -1
        )"
        :value="country"
        :key="country.Name"
        @click="onSelect(country)"
        :active="currentCountry ? currentCountry.Code === country.Code : false"
        class="f-c-c"
        button
      >
        <country-flag :country="country.Code" rounded class="menuCountryFlex" />
        <span>
          {{ country.Name }}
        </span>
      </b-list-group-item>
    </b-list-group>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TheCountrySelectionModal",
  props: {
    onSelect: Function,
    onClose: Function,
    id: String
  },
  data() {
    return {
      searchCountry: ""
    };
  },
  computed: {
    ...mapGetters(["countries"]),
    ...mapGetters({ currentCountry: "country" })
  },
  methods: {
    onCloseModal() {
      this.searchCountry = "";
    }
  }
};
</script>
<style type="text/css">
.countrySelectHeader {
  flex-direction: row;
}
.choose-country-content {
  max-height: 50vh !important;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
