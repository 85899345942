<template>
  <div>
    <div class="bg-primary" id="navBar">
      <b-container class="mx-auto p-0">
        <TheSignIn :key="componentKey" />
        <b-navbar toggleable="lg" type="dark">
          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-brand>
              <router-link :to="{ name: 'Home' }" class="navbar-brand">
                <img src="@/assets/img/Pash-logo.svg" alt="pash" title="logo" />
              </router-link>
            </b-navbar-brand>
            <b-navbar-nav class="navbar-wrapper">
              <b-nav-item
                :to="{ name: 'Buy' }"
                :active="
                  $route.path.includes('search') ||
                    $route.path.includes('overview') ||
                    $route.path.includes('reservation')
                "
                exact-active-class="active"
                class="navItem"
                >{{ $t("navBar.label.buy") }}
              </b-nav-item>
              <b-nav-item
                :to="{ name: 'Sell' }"
                exact
                exact-active-class="active"
                class="navItem"
                >{{ $t("navBar.label.sell") }}</b-nav-item
              >
              <b-nav-item-dropdown
                class="navDrop"
                ref="navDropWorkWithPASH"
                :class="{
                  active:
                    $route.path.includes('inspection-agents') ||
                    $route.path.includes('freight-forwarders') ||
                    $route.path.includes('customs-brokers') ||
                    $route.path.includes('sales-representatives') ||
                    $route.path.includes('jobs'),
                }"
              >
                <template #text>
                  <span style="color: white">{{
                    $t("navBar.label.workWithPASH")
                  }}</span>
                </template>
                <!--<b-dropdown-item
                  :to="{ name: 'Contact',params:{type:'sellers'} }"
                  exact
                  exact-active-class="active"
                  >{{ $t("navBar.label.sellers") }}</b-dropdown-item
                >-->
                <b-dropdown-item
                  :to="{
                    name: 'Contact',
                    params: { type: 'inspection-agents' },
                  }"
                  exact
                  exact-active-class="active"
                >
                  {{ $t("navBar.label.inspectionAgents") }}
                </b-dropdown-item>
                <b-dropdown-item
                  :to="{
                    name: 'Contact',
                    params: { type: 'freight-forwarders' },
                  }"
                  exact
                  exact-active-class="active"
                >
                  {{ $t("navBar.label.freightForwarders") }}
                </b-dropdown-item>
                <b-dropdown-item
                  :to="{ name: 'Contact', params: { type: 'customs-brokers' } }"
                  exact
                  exact-active-class="active"
                >
                  {{ $t("navBar.label.customsBrokers") }}
                </b-dropdown-item>
                <b-dropdown-item
                  :to="{
                    name: 'Contact',
                    params: { type: 'sales-representatives' },
                  }"
                  exact
                  exact-active-class="active"
                >
                  {{ $t("navBar.label.salesRepresentatives") }}
                </b-dropdown-item>
                <b-dropdown-item
                  :to="{ name: 'Contact', params: { type: 'jobs' } }"
                  exact
                  exact-active-class="active"
                >
                  {{ $t("navBar.label.jobs") }}
                </b-dropdown-item>
              </b-nav-item-dropdown>
            </b-navbar-nav>

            <!-- Right aligned nav items -->

            <b-navbar-nav class="ml-auto" id="siginButton">
              <div v-if="logState" class="namebar">
                <b-nav-item-dropdown right menu-class="" no-caret>
                  <!-- temporary user profile and logout nav -->
                  <template #button-content>
                    <span
                      class="h5 text-capitalize text-white mb-0 d-inline-flex align-items-center"
                      v-if="firstName && lastName"
                    >
                      {{ firstName }}
                    </span>
                    <span
                      class="h5 text-capitalize text-white mr-1 mb-0 d-inline-flex align-items-center"
                      v-if="firstName && lastName"
                    >
                      {{ lastName[0] }}.
                    </span>
                    <span
                      class="h5 text-white mr-1 mb-0 d-inline-flex align-items-center"
                      v-else
                    >
                      {{ user.UserName }}
                    </span>
                    <b-icon
                      icon="person-circle"
                      class="text-white mr-2 person-circle"
                    >
                    </b-icon>
                    <!-- emailValidated -->
                    <b-icon
                      id="validationAlert"
                      icon="exclamation-circle-fill"
                      variant="warning"
                      class="bg-dark"
                      style="
                        border-radius: 50px;
                        padding: 2px;
                        margin-left: -13px;
                        width: 15px;
                        height: 15px;
                      "
                      v-show="needValidate"
                    ></b-icon>
                  </template>

                  <b-popover
                    v-if="logState"
                    ref="validationAlert"
                    target="validationAlert"
                    variant="primary"
                    placement="bottom"
                    style="width: 400px"
                    triggers="hover"
                    title="Validation Required"
                    :noninteractive="true"
                  >
                    <!-- <p class="lead">
                    Please validate the items below so that you may begin using
                    Pash
                  </p> -->
                    <div
                      class="border-bottom mb-3 text-center"
                      v-show="!user.IsEmailVerified"
                    >
                      <p class="font-weight-bold mb-0">
                        Validate your email address {{ user.Contact.Email }}
                      </p>
                      <section
                        class="border border-danger mt-3 text-center p-0"
                        v-show="errorMessage"
                      >
                        <b class="text-danger">{{ errorMessage }}</b>
                      </section>
                      <b-btn
                        variant="primary"
                        size="sm"
                        class="px-5 my-1"
                        @click="resendEmail(isSeller ? 2 : 1)"
                        >Validate Email</b-btn
                      >
                    </div>
                    <div
                      class="border-bottom mb-3 text-center"
                      v-show="!user.IsMobileVerified && isSeller"
                    >
                      <p class="font-weight-bold mb-0">
                        Validate your mobile phone number
                        {{ user.Contact.Mobile }}
                      </p>
                      <section
                        class="border border-danger mt-3 text-center p-0"
                        v-show="errorMessage"
                      >
                        <b class="text-danger">{{ errorMessage }}</b>
                      </section>
                      <b-btn
                        variant="primary"
                        size="sm"
                        class="px-5 my-1"
                        @click="resendCode(isSeller ? 2 : 1)"
                        >Validate Mobile</b-btn
                      >
                    </div>
                    <p>
                      You will not be able to transact on PASH until your
                      profile is completed. Visit your profile page to supply
                      the missing information.
                    </p>
                    <div class="text-right">
                      <b-link
                        :to="{
                          name: 'PrimaryContact',
                          params: { id: $store.getters.user.UserId },
                        }"
                        >View Profile</b-link
                      >
                    </div>
                  </b-popover>
                  <!-- <b-dropdown-item
                  :to="{
                    name: buyer ? 'BuyerDashboard' : 'SellerDashboard',
                    params: { id: $store.getters.user.UserId }
                  }"
                  >{{ $t("navBar.label.dashboards") }}</b-dropdown-item
                > -->
                  <b-dropdown-item
                    v-if="user.Roles.includes('Buyer')"
                    @click="needValidate ? onOpenAlert() : $router.push('/dashboards/buyerdashboard')"
                  >
                    {{ $t("PageTitle.BuyerDashboard") }}
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="user.Roles.includes('Seller')"
                    @click="needValidate ? onOpenAlert() : $router.push('/dashboards/sellerdashboard')"
                  >
                    {{ $t("PageTitle.SellerDashboard") }}
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="needValidate ? onOpenAlert() : gotoProfile()"
                    >{{ $t("navBar.label.myProfile") }}</b-dropdown-item
                  >
                  <b-dropdown-item @click="signOutClick" id="logout">{{
                    $t("navBar.label.logout")
                  }}</b-dropdown-item>
                </b-nav-item-dropdown>
              </div>
              <div class="button-wrapper">
                <div v-if="!logState">
                  <b-button
                    pill
                    size="md"
                    variant="outline-warning"
                    class="px-5 py-2 signin none-lg"
                    v-b-modal.signin
                    @click="forceRerender()"
                  >
                    {{ $t("navBar.label.signIn") }}
                  </b-button>
                  <b-button
                    class="py-2 signin mobile show-lg"
                    v-b-modal.signin
                    @click="forceRerender()"
                  >
                    <img src="@/assets/img/icons/login.svg" alt="" />
                  </b-button>
                </div>
                <b-button
                  class="py-2 bars show-lg"
                  @click="showMobileMenu = true"
                >
                  <img src="@/assets/img/icons/bars.svg" alt="" />
                </b-button>
              </div>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </b-container>
      <TheSellerChecking v-if="user" />
      <TheNoCountry />
    </div>
    <!--  -->
    <div class="mobile-menu" v-if="!$route.path.includes('overview')">
      <b-navbar-nav>
        <b-nav-item
          :to="{ name: 'Buy' }"
          exact
          :active="
            $route.path.includes('search') ||
              $route.path.includes('overview') ||
              $route.path.includes('reservation')
          "
          exact-active-class="active"
          class="navItem"
          >{{ $t("navBar.label.buy") }}
        </b-nav-item>
        <b-nav-item
          :to="{ name: 'Sell' }"
          exact
          exact-active-class="active"
          class="navItem"
          >{{ $t("navBar.label.sell") }}</b-nav-item
        >
        <b-nav-item-dropdown
          class="navDrop"
          ref="navDropWorkWithPASH"
          :class="{
            active: hasActiveChildren,
            fixActive: hasActiveChildren,
          }"
        >
          <template #text>
            <span style="color: white">{{
              $t("navBar.label.workWithPASH")
            }}</span>
          </template>
          <!--<b-dropdown-item
                  :to="{ name: 'Contact',params:{type:'sellers'} }"
                  exact
                  exact-active-class="active"
                  >{{ $t("navBar.label.sellers") }}</b-dropdown-item
                >-->
          <b-dropdown-item
            :to="{
              name: 'Contact',
              params: { type: 'inspection-agents' },
            }"
            exact
            exact-active-class="active"
          >
            {{ $t("navBar.label.inspectionAgents") }}
          </b-dropdown-item>
          <b-dropdown-item
            :to="{
              name: 'Contact',
              params: { type: 'freight-forwarders' },
            }"
            exact
            exact-active-class="active"
          >
            {{ $t("navBar.label.freightForwarders") }}
          </b-dropdown-item>
          <b-dropdown-item
            :to="{ name: 'Contact', params: { type: 'customs-brokers' } }"
            exact
            exact-active-class="active"
          >
            {{ $t("navBar.label.customsBrokers") }}
          </b-dropdown-item>
          <b-dropdown-item
            :to="{
              name: 'Contact',
              params: { type: 'sales-representatives' },
            }"
            exact
            exact-active-class="active"
          >
            {{ $t("navBar.label.salesRepresentatives") }}
          </b-dropdown-item>
          <b-dropdown-item
            :to="{ name: 'Contact', params: { type: 'jobs' } }"
            exact
            exact-active-class="active"
          >
            {{ $t("navBar.label.jobs") }}
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </div>
    <!--  -->
    <div class="hamburger-menu" :class="showMobileMenu ? 'open' : ''">
      <div class="hamburger-menu-header">
        <div class="title">Menu</div>
        <button class="button button-close" @click="showMobileMenu = false">
          <RemoveIcon />
        </button>
      </div>
      <div class="hamburger-menu-body">
        <ul>
          <li>
            <b-link
              :to="{ name: 'Buy' }"
              :active="
                $route.path.includes('search') ||
                  $route.path.includes('overview') ||
                  $route.path.includes('reservation')
              "
              >{{ $t("navBar.label.buy") }}
            </b-link>
          </li>
          <li>
            <b-link :to="{ name: 'Sell' }">{{
              $t("navBar.label.sellers")
            }}</b-link>
          </li>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                {{ $t("navBar.label.workWithPASH") }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <ul class="sub-dropdown">
                  <li>
                    <b-link
                      :to="{
                        name: 'Contact',
                        params: { type: 'inspection-agents' },
                      }"
                      >{{ $t("navBar.label.inspectionAgents") }}</b-link
                    >
                  </li>
                  <li>
                    <b-link
                      :to="{
                        name: 'Contact',
                        params: { type: 'freight-forwarders' },
                      }"
                      >{{ $t("navBar.label.freightForwarders") }}</b-link
                    >
                  </li>
                  <li>
                    <b-link
                      :to="{
                        name: 'Contact',
                        params: { type: 'customs-brokers' },
                      }"
                      >{{ $t("navBar.label.customsBrokers") }}</b-link
                    >
                  </li>
                  <li>
                    <b-link
                      :to="{
                        name: 'Contact',
                        params: { type: 'sales-representatives' },
                      }"
                      >{{ $t("navBar.label.salesRepresentatives") }}</b-link
                    >
                  </li>
                  <li>
                    <b-link
                      :to="{ name: 'Contact', params: { type: 'jobs' } }"
                      >{{ $t("navBar.label.jobs") }}</b-link
                    >
                  </li>
                </ul>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <li><b-link :to="{ name: 'Contact' }">{{ $t("topbar.label.contactUs") }}</b-link></li>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                {{locales[$i18n.locale].name}}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <ul class="sub-dropdown">
                  <li
                      v-for="lang in locales"
                      :key="lang.name"
                      @click="setLocale(lang.value)"
                  >
                    <b-link>
                      {{ lang.name }}
                    </b-link>
                  </li>
                </ul>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </ul>
        <b-button
          class="sidebar-signin"
          v-b-modal.signin
          @click="showMobileMenu = false"
        >
          {{ $t("navBar.label.signIn") }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import TheSignIn from "@/components/global/TheSignIn.vue";
import TheNoCountry from "@/components/global/TheRegisterNoCountry.vue";
import { mapGetters } from "vuex";
import TheSellerChecking from "@/components/global/TheSellerChecking.vue";
import RemoveIcon from "vue-material-design-icons/Close.vue";
import axios from "axios";

export default {
  name: "navBar",
  components: {
    TheSignIn,
    TheNoCountry,
    TheSellerChecking,
    RemoveIcon,
  },
  props: {
    isTransparent: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      componentKey: 0,
      emailValidated: true,
      mobileValidated: true,
      errorMessage: null,
      showMobileMenu: false,
      locales: {
        en: { value: "en", name: "English" },
        fr: { value: "fr", name: "Français" },
      },

    };
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;
    },
    signOutClick() {
      this.sendToken();
      localStorage.clear();
      this.$store.dispatch("user", null);
      this.$store.dispatch("tokenID", null);
      window.location.assign("/");
    },
    async sendToken() {
      await axios
        .post(
          "SP/post/sp_Logout",
          {
            TokenId: localStorage.getItem("token"),
          },
          { timeout: 3000 }
        )
        .catch((error) => {
          console.log(error);
        });
    },
    async resendEmail(typeNumber) {
      this.$store.dispatch("accountType", typeNumber);
      this.$router.push({ name: "RegisterPage" });
      try {
        await axios.post("user/EmailResendCode", {
          LoginKey: this.$store.getters.tokenID,
        });
      } catch (error) {
        this.errorMessage = error.response.data;
        console.log(error.response.data);
      }
    },
    async resendCode(typeNumber) {
      this.$store.dispatch("accountType", typeNumber);
      this.$router.push({ name: "RegisterPage" });
      try {
        await axios.post("user/MobileResendCode", {
          LoginKey: this.$store.getters.tokenID,
        });
      } catch (error) {
        this.errorMessage = error.response.data;
        console.log(error.response.data);
      }
    },
    gotoDashboard() {
      this.$router.push({
        name: this.isSeller ? "SellerDashboard" : "BuyerDashboard",
      });
    },
    gotoProfile() {
      this.$router.push({
        name: "PrimaryContact",
      });
    },
    onOpenAlert() {
      this.$refs.validationAlert.$emit("open");
    },
    onOverWorkWithPASH() {
      this.$refs.navDropWorkWithPASH.visible = true;
    },
    onLeaveWorkWithPASH() {
      this.$refs.navDropWorkWithPASH.visible = false;
    },
    handleScroll() {
      if (this.$route.name === "Buy" || this.$route.name === "Sell") {
        const menu = document.querySelector("#navBar");
        const mobileMenu = document.querySelector(".mobile-menu");
        if (
          document.body.scrollTop > 40 ||
          document.documentElement.scrollTop > 40
        ) {
          menu.classList.add("nav-fix");
          mobileMenu.classList.add("is-fix");
        } else {
          menu.classList.remove("nav-fix");
          mobileMenu.classList.remove("is-fix");
        }
      } else {
        const menu = document.querySelector("#navBar");
        const mobileMenu = document.querySelector(".mobile-menu");
        if(menu) menu.classList.remove("nav-fix");
        if(mobileMenu) mobileMenu.classList.remove("is-fix");
      }
    },
    async setLocale(value) {
      let locale = value;
      localStorage.setItem("locale", locale);
      if (this.$store.state.user.user) {
        this.$store.dispatch("updateUser", {
          ID: this.$store.state.user.user.UserId,
          LangCode: locale,
        });
      }
      await this.$store.dispatch("removeAllCache");
      location.reload()
    },

  },
  computed: {
    ...mapGetters(["user"]),
    logState() {
      return Boolean(this.user && localStorage.getItem("token"));
    },
    firstName() {
      return this.user.Contact.FirstName;
    },
    lastName() {
      return this.user.Contact.LastName;
    },
    needValidate() {
      if (this.isSeller) {
        return !this.user.IsEmailVerified || !this.user.IsMobileVerified;
      } else {
        return !this.user.IsEmailVerified;
      }
    },
    isSeller() {
      return this.user ? this.user.Roles.includes("Seller") : false;
    },
    hasActiveChildren() {
      return this.$route["path"].indexOf("/work/") !== -1;
    },
    isDashboardPage() {
      if (this.$route.matched[0]) {
        if (this.$route.matched[0].path === "/dashboards") {
          return true;
        } else return false;
      }
      return false;
    },
    currentCountry: {
      get() {
        return this.$store.state.user.country;
      },
      set(country) {
        if (!this.currency) {
          this.currency = country.Currency;
        }
        this.$store.dispatch("setCountry", country);
      },
    },

  },
  watch: {
    "$route.path"() {
      this.showMobileMenu = false;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style lang="scss">
.nav-fix {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}
.is-fix {
  top: 43px;
  position: fixed;
  width: 100%;
  @media (max-width: 575px) {
    top: 40px !important;
  }
}
</style>
<style scoped lang="scss">
.navbar-dark .navbar-nav .nav-link {
  color: white !important;
}
.namebar {
  position: relative;
  @media (max-width: 991px) {
    height: 40px;
    display: flex;
    align-items: center;
  }
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background: transparent;
    top: -20px;
    right: 0;
    transition: 0.3s;
    @media (max-width: 991px) {
      top: -2px;
    }
    @media (max-width: 575px) {
      top: 0;
    }
  }
  &:hover {
    &:before {
      background: #fedf01;
    }
  }
}

.fixActive {
  border-top: 2px solid #fedf01 !important;
  margin-top: -15px;
  padding-top: 13px;
  color: #fedf01 !important;
}
.navItem {
  width: 4em;
  text-align: center;
  margin-right: 5px;
  position: relative;
  @media (max-width: 991px) {
    width: auto;
    text-align: center;
    margin: 0 16px !important;
  }
}
.nav-link,
.navDrop {
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    top: -15px;
    height: 2px;
    background: transparent;
    right: 0;
    transition: all 0.3s;
    @media (max-width: 991px) {
      top: 0;
    }
  }
}
.nav-link.active,
.nav-link:hover,
.navDrop:hover,
.navDrop.active,
.navDrop:hover {
  &:before {
    background: #fedf01;
  }
  a {
    color: #fedf01 !important;
  }
  span {
    color: #fedf01 !important;
  }
}

.navDrop.active {
  span {
    color: #fedf01 !important;
  }
}

.navDrop {
  outline: none !important;
  @media (max-width: 991px) {
    margin: 0 16px !important;
  }
}
.side_distance {
  padding-left: 105px;
  padding-right: 105px;
  max-width: 1532px;
}
.nav_distance {
  margin-left: 121px;
}
.none-lg {
  display: block;
}
.show-lg {
  display: none;
}
.collapse:not(.show) {
  display: flex !important;
}
.mobile-menu {
  height: 44px;
  background: #006ac3 !important;
  border-top: 1px solid #ffffff3d;
  border-bottom: 1px solid #ffffff3d;
  .navbar-nav {
    flex-wrap: nowrap;
    padding: 0;
    flex-direction: row;
  }
  li {
    a {
      color: white !important;
    }
  }
}

@media (max-width: 991px) {
  .navbar-brand {
    width: 68px;
    display: flex;
    align-items: cente;
  }
  .navbar-brand img {
    width: 100%;
  }
  .collapse {
    height: 27px;
  }
  .navDrop:hover:not(.active),
  .navItem[data-v-4d5889e5]:hover:not(.active) {
    margin-top: -1px;
    padding-top: 0;
  }
  .navbar-wrapper {
    display: none !important;
  }
  .none-lg {
    display: none;
  }
  .show-lg {
    display: block;
  }
  .signin.mobile {
    width: auto;
    background: none !important;
    border: none;
    display: flex;
    align-items: center;
  }
  .button-wrapper {
    display: flex;
    align-items: center;
  }
  .bars {
    padding: 0;
    width: 32px;
    height: 32px;
    background: none !important;
    border: none;
    margin-left: 5px;
    display: flex;
    align-items: center;
    transform: scale(1.03);
    @media (max-width: 575px) {
      transform: scale(0.8);
    }
  }
}
@media (min-width: 991px) {
  .mobile-menu {
    display: none !important;
  }
}
#siginButton {
  flex-direction: row !important;
}
.hamburger-menu {
  padding: 24px 16px;
  background: white;
  position: fixed;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  background: white;
  z-index: 9999999;
  overflow-y: auto;
  transform: translateX(100%);
  transition: 0.3s;
  &.open {
    @media (max-width: 991px) {
      transform: translateX(0);
    }
  }
  &-header {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid #dbdbdb;
    font-weight: 600;
    font-size: 16px;
  }
  &-body {
    ul {
      padding: 0;
    }
    li {
      border-bottom: 1px solid #dbdbdb;
      list-style: none;
      height: 56px;
      display: flex;
      align-items: center;
      padding: 0 16px;
    }
    a {
      color: #111 !important;
      &:hover,
      &:active {
        color: #006ac3 !important;
      }
      &.router-link-active,
      &.router-link-axact-active {
        color: #006ac3 !important;
      }
    }

    .v-expansion-panel {
      border-bottom: 1px solid #dbdbdb;
      border-radius: 0 !important;
      &-header {
        padding: 16px;
        min-height: auto;

        &--active {
          color: #006ac3 !important;
        }
      }
      &:before {
        display: none;
      }
    }

    .sub-dropdown {
      padding-left: 24px;
      li {
        padding: 0;
        height: 40px;
        border: none;
      }
    }
  }
  .sidebar-signin {
    height: 44px;
    border-radius: 40px;
    background-color: #006ac3;
    color: white;
    margin-top: 8px;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
  }
}
#navBar {
  @media (max-width: 575px) {
    height: 40px;
  }
  .navbar {
    @media (max-width: 575px) {
      padding: 0 16px;
      height: 40px;
    }
  }
  #nav-collapse {
    @media (max-width: 575px) {
      height: 100%;
    }
  }
}
.person-circle {
  width: 30px;
  height: 30px;
  @media (max-width: 575px) {
    width: auto;
    height: auto;
  }
}
.signin {
  @media (max-width: 575px) {
    padding-right: 5px;
  }
  img {
    @media (max-width: 575px) {
      width: 20px;
    }
  }
}
</style>
