import _cloneDeep from 'lodash/cloneDeep'
const initialState = {
  reservationContactFirstName: null,
  reservationContactLastName: null,
  reservationContactMobile: null,
  reservationContactMobileCountryCode: null,
  reservationContactEmail: null,
  reservationContactAddress1: null,
  reservationContactAddress2: null,
  reservationContactCity: null,
  reservationContactState: null,
  reservationContactZip: null,
  reservationContactCountry: null,

  reservationOther_ContactFirstName: null,
  reservationOther_ContactLastName: null,
  reservationOther_ContactMobileCountryCode: null,
  reservationOther_ContactMobile: null,
  reservationOther_ContactEmail: null,
  reservationOther_ContactAddress1: null,
  reservationOther_ContactAddress2: null,
  reservationOther_ContactCity: null,
  reservationOther_ContactState: null,
  reservationOther_ContactZip: null,
  reservationOther_ContactCountry: null,

  reservationSuccess: false,
  reservationFailed: false,

  reservationNumber: null,
  reservationSameAddress: true,
  reservationPhoneVerification: false,
  reservationAgent: null,
  payResult: null,

  reservationKeepData: false

}
export default {
  state: {
    ..._cloneDeep(initialState)
  },
  getters: {
    reservationGet: state => {
      return state;
    },
    reservationContactFirstName: state => {
      return state.reservationContactFirstName;
    },
    reservationContactLastName: state => {
      return state.reservationContactLastName;
    },
    reservationContactMobileCountryCode: state => {
      return state.reservationContactMobileCountryCode;
    },
    reservationContactMobile: state => {
      return state.reservationContactMobile;
    },
    reservationContactEmail: state => {
      return state.reservationContactEmail;
    },
    reservationContactAddress1: state => {
      return state.reservationContactAddress1;
    },
    reservationContactAddress2: state => {
      return state.reservationContactAddress2;
    },
    reservationContactCity: state => {
      return state.reservationContactCity;
    },
    reservationContactState: state => {
      return state.reservationContactState;
    },
    reservationContactZip: state => {
      return state.reservationContactZip;
    },
    reservationContactCountry: state => {
      return state.reservationContactCountry;
    },
    reservationOther_ContactFirstName: state => {
      return state.reservationOther_ContactFirstName;
    },
    reservationOther_ContactLastName: state => {
      return state.reservationOther_ContactLastName;
    },
    reservationOther_ContactMobileCountryCode: state => {
      return state.reservationOther_ContactMobileCountryCode;
    },
    reservationOther_ContactMobile: state => {
      return state.reservationOther_ContactMobile;
    },
    reservationOther_ContactEmail: state => {
      return state.reservationOther_ContactEmail;
    },
    reservationOther_ContactAddress1: state => {
      return state.reservationOther_ContactAddress1;
    },
    reservationOther_ContactAddress2: state => {
      return state.reservationOther_ContactAddress2;
    },
    reservationOther_ContactCity: state => {
      return state.reservationOther_ContactCity;
    },
    reservationOther_ContactState: state => {
      return state.reservationOther_ContactState;
    },
    reservationOther_ContactZip: state => {
      return state.reservationOther_ContactZip;
    },
    reservationOther_ContactCountry: state => {
      return state.reservationOther_ContactCountry;
    },
    reservationSuccess: state => {
      return state.reservationSuccess;
    },
    reservationFailed: state => {
      return state.reservationFailed;
    },
    reservationNumber: state => {
      return state.reservationNumber;
    },
    reservationSameAddress: state => {
      return state.reservationSameAddress;
    },
    reservationPhoneVerification: state => {
      return state.reservationPhoneVerification;
    },
    reservationAgent: state => {
      return state.reservationAgent;
    },
    reservationKeepData: state => {
      return state.reservationKeepData;
    }
  },
  actions: {
    reservationContactFirstName(context, value) {
      context.commit("reservationContactFirstName", value);
    },
    reservationContactLastName(context, value) {
      context.commit("reservationContactLastName", value);
    },
    reservationContactMobileCountryCode(context, value) {
      context.commit("reservationContactMobileCountryCode", value);
    },
    reservationContactMobile(context, value) {
      context.commit("reservationContactMobile", value);
    },
    reservationContactEmail(context, value) {
      context.commit("reservationContactEmail", value);
    },
    reservationContactAddress1(context, value) {
      context.commit("reservationContactAddress1", value);
    },
    reservationContactAddress2(context, value) {
      context.commit("reservationContactAddress2", value);
    },
    reservationContactCity(context, value) {
      context.commit("reservationContactCity", value);
    },
    reservationContactState(context, value) {
      context.commit("reservationContactState", value);
    },
    reservationContactZip(context, value) {
      context.commit("reservationContactZip", value);
    },
    reservationContactCountry(context, value) {
      context.commit("reservationContactCountry", value);
    },
    reservationOther_ContactFirstName(context, value) {
      context.commit("reservationOther_ContactFirstName", value);
    },
    reservationOther_ContactLastName(context, value) {
      context.commit("reservationOther_ContactLastName", value);
    },
    reservationOther_ContactMobileCountryCode(context, value) {
      context.commit("reservationOther_ContactMobileCountryCode", value);
    },
    reservationOther_ContactMobile(context, value) {
      context.commit("reservationOther_ContactMobile", value);
    },
    reservationOther_ContactEmail(context, value) {
      context.commit("reservationOther_ContactEmail", value);
    },
    reservationOther_ContactAddress1(context, value) {
      context.commit("reservationOther_ContactAddress1", value);
    },
    reservationOther_ContactAddress2(context, value) {
      context.commit("reservationOther_ContactAddress2", value);
    },
    reservationOther_ContactCity(context, value) {
      context.commit("reservationOther_ContactCity", value);
    },
    reservationOther_ContactState(context, value) {
      context.commit("reservationOther_ContactState", value);
    },
    reservationOther_ContactZip(context, value) {
      context.commit("reservationOther_ContactZip", value);
    },
    reservationOther_ContactCountry(context, value) {
      context.commit("reservationOther_ContactCountry", value);
    },
    reservationSuccess(context, value) {
      context.commit("reservationSuccess", value);
    },
    reservationFailed(context, value) {
      context.commit("reservationFailed", value);
    },
    reservationNumber(context, value) {
      context.commit("reservationNumber", value);
    },
    reservationSameAddress(context, value) {
      context.commit("reservationSameAddress", value);
    },
    reservationPhoneVerification(context, value) {
      context.commit("reservationPhoneVerification", value);
    },
    reservationAgent(context, value) {
      context.commit("reservationAgent", value);
    },
    reservationKeepData(context, value) {
      context.commit("reservationKeepData", value);
    },
    reservationPayResult(context, value) {
      context.commit("reservationPayResult", value);
    },
    clearReservation(context) {
      context.commit('clearReservation')
    }
  },
  mutations: {
    reservationPayResult(state, value){
      state.payResult = value
    },
    reservationContactLastName(state, value) {
      state.reservationContactLastName = value;
    },
    reservationContactFirstName(state, value) {
      state.reservationContactFirstName = value;
    },
    reservationContactMobileCountryCode(state, value) {
      state.reservationContactMobileCountryCode = value;
    },
    reservationContactMobile(state, value) {
      state.reservationContactMobile = value;
    },
    reservationContactEmail(state, value) {
      state.reservationContactEmail = value;
    },
    reservationContactAddress1(state, value) {
      state.reservationContactAddress1 = value;
    },
    reservationContactAddress2(state, value) {
      state.reservationContactAddress2 = value;
    },
    reservationContactCity(state, value) {
      state.reservationContactCity = value;
    },
    reservationContactState(state, value) {
      state.reservationContactState = value;
    },
    reservationContactZip(state, value) {
      state.reservationContactZip = value;
    },
    reservationContactCountry(state, value) {
      state.reservationContactCountry = value;
    },
    reservationOther_ContactMobileCountryCode(state, value) {
      state.reservationOther_ContactMobileCountryCode = value;
    },
    reservationOther_ContactMobile(state, value) {
      state.reservationOther_ContactMobile = value;
    },
    reservationOther_ContactFirstName(state, value) {
      state.reservationOther_ContactFirstName = value;
    },
    reservationOther_ContactLastName(state, value) {
      state.reservationOther_ContactLastName = value;
    },
    reservationOther_ContactEmail(state, value) {
      state.reservationOther_ContactEmail = value;
    },
    reservationOther_ContactAddress1(state, value) {
      state.reservationOther_ContactAddress1 = value;
    },
    reservationOther_ContactAddress2(state, value) {
      state.reservationOther_ContactAddress2 = value;
    },
    reservationOther_ContactCity(state, value) {
      state.reservationOther_ContactCity = value;
    },
    reservationOther_ContactState(state, value) {
      state.reservationOther_ContactState = value;
    },
    reservationOther_ContactZip(state, value) {
      state.reservationOther_ContactZip = value;
    },
    reservationOther_ContactCountry(state, value) {
      state.reservationOther_ContactCountry = value;
    },
    reservationSuccess(state, value) {
      state.reservationSuccess = value;
    },
    reservationFailed(state, value) {
      state.reservationFailed = value;
    },
    reservationNumber(state, value) {
      state.reservationNumber = value;
    },
    reservationSameAddress(state, value) {
      state.reservationSameAddress = value;
    },
    reservationPhoneVerification(state, value) {
      state.reservationPhoneVerification = value;
    },
    reservationAgent(state, value) {
      state.reservationAgent = value;
    },
    reservationKeepData(state, value) {
      state.reservationKeepData = value;
    },
    clearReservation(state){
      Object.keys(state).map(key => {
        state[key] = initialState[key]
      })
    }
  }
};
