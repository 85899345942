<template>
  <div class="d-flex align-items-center justify-content-center">
    <TheCountrySelectionModal
      id="choose-country"
      :visible="popoverShowCountry"
      :onSelect="setCountry"
    />
    <div
      v-if="currentCountry"
      @click="$bvModal.show('choose-country')"
      class="f-c-c language-picker-flag"
    >
      <country-flag :country="currentCountry.Code ? currentCountry.Code : ''" rounded />
    </div>
    <div v-else class="topbar-text">
      {{ $t("topbar.label.selectCountry") }}
    </div>
    <div class="nav-right-divider" />
    <b-dropdown variant="link" class="langSelector">
      <template #button-content class="text-white bg-transparent border-0 noOutline">
        <span class="d-none d-lg-block">{{ locales[$i18n.locale].name }}</span>
        <span class="d-block d-lg-none">{{
          locales[$i18n.locale].name.substr(0, 2)
        }}</span>
      </template>
      <b-dropdown-item
        v-for="lang in locales"
        :value="lang.value"
        :key="lang.name"
        @click="setLocale(lang.value)"
      >
        {{ lang.name }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import { loadLanguageAsync } from "@/i18n";
import { mapGetters } from "vuex";
import axios from "axios";
import TheCountrySelectionModal from "./TheCountrySelectionModal";

export default {
  components: {
    TheCountrySelectionModal,
  },
  data() {
    return {
      locale: this.$i18n.locale,
      popoverShow: false,
      popoverShowCountry: false,
      ip: null,
      errorMessage: null,
      locales: {
        en: { value: "en", name: "English" },
        fr: { value: "fr", name: "Français" },
      },
    };
  },
  computed: {
    ...mapGetters(["countries", "activeCountries", "currencies", "currency"]),
    ...mapGetters({ currentCountry: "country" }),
    activeCountries() {
      return this.$store.getters.activeCountries;
    },
    buyerCountries() {
      return this.$store.getters.activeBuyerCountries;
    },
    sellerCountries() {
      return this.$store.getters.activeSellerCountries;
    },
    currencies() {
      return this.$store.state.user.currencies;
    },
    currency: {
      get() {
        return this.$store.state.user.currency;
      },
      set(currency) {
        this.$store.dispatch("setCurrency", currency);
      },
    },
    currentCountry: {
      get() {
        return this.$store.state.user.country;
      },
      set(country) {
        if (!this.currency) {
          this.currency = country.Currency;
        }
        this.$store.dispatch("setCountry", country);
      },
    },
  },
  methods: {
    onClose() {
      this.popoverShow = false;
    },
    async setLocale(value) {
      let locale = value;
      localStorage.setItem("locale", locale);
      //this.$i18n.locale = locale;
      //await loadLanguageAsync(locale);
      //axios.defaults.headers.common["languageCode"] = locale;
      //await this.$store.dispatch("setLang", locale);
      //this.$root.$emit("changeLang");
      if (this.$store.state.user.user) {
        this.$store.dispatch("updateUser", {
          ID: this.$store.state.user.user.UserId,
          LangCode: locale,
        });
      }
      // this.$store.dispatch("pageContentFromApi");
      await this.$store.dispatch("removeAllCache");
      //this.setUser(locale);
      location.reload()
      //this.$router.go();
    },
    async setUser(lang) {
      try {
        await axios.post("user/SetUser", {
          LanguageCode: lang,
        });
      } catch (error) {
        console.log(error);
      }
    },
    setCountry(country) {
      if (!this.currency) {
        this.currency = country.Currency;
      }
      this.$store.dispatch("setCountry", country);
      this.setCurrency(country.Code);
      this.$store.dispatch("getCurrencyCulture", this.$i18n.locale);
      this.$bvModal.hide("choose-country");
    },
    setCurrency(e) {
      let buyerCurrencies = [];
      this.currencies.forEach((element) => {
        if (element.IsGlobal || element.Buyer) {
          buyerCurrencies.push(element);
        }
      });
      if (this.buyerCurrencies) {
        let cur = this.buyerCurrencies.filter((el) => el.CountryCode == e);
        if (cur) {
          this.$store.dispatch("setCurrency", cur[0].Code);
        }
      }

      if (this.$store.state.user.user) {
        this.$store.dispatch("updateUser", {
          ID: this.$store.state.user.user.UserId,
          CurrencyCode: e,
        });
      }
    },
    // popup() {
    //   this.$refs.popup.manualLang();
    // }
    async autoLang() {
      await this.$store.dispatch('getCurrentCountry', {locale: this.$i18n.locale, forceChangeCurrency: true})
    },
    popoverSwitch() {
      if (!this.popoverShow) {
        this.popoverShowCountry = false;
      } else if (!this.popoverShowCountry) {
        this.onClose();
      }
    },
    openCountrySelector() {
      this.popoverShowCountry = true;
    },
    closeCountrySelector() {
      this.popoverShowCountry = false;
    },
  },
  watch: {
    countries() {
      this.autoLang();
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/custom.scss";
.popover {
  max-width: 600px !important;
  margin-top: 0;
}
.noOutline:focus {
  outline: none !important;
  box-shadow: none;
}
::-webkit-scrollbar-thumb {
  background: $gray-200;
}
.outline:hover {
  color: #d9d9d9 !important;
  border-bottom: 1px solid #d9d9d9 !important;
  margin-bottom: -1px;
}
.nav-right-divider {
  border-right: 1px solid #fff;
  height: 25px;
  width: 1px;
}
.language-picker-flag {
  position: relative;
  cursor: pointer;
}
</style>
<style lang="scss">
.language-picker-flag:hover:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom: 2px solid #fedf01 !important;
  cursor: pointer;
}

.popover .arrow {
  display: none !important;
}
.langSelector .btn:hover:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom: 2px solid #fedf01 !important;
  cursor: pointer;
}
.langSelector .btn,
.langSelector .btn:hover {
  position: relative;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  text-decoration: none;
}

.countrySelectMenu.dropdown-menu {
  min-height: 300px;
  min-width: 400px;
  max-height: 40vh;
  overflow: auto;
}
.menuCountryFlex {
  display: inline-block;
  vertical-align: middle;
}
.topbar-text {
  color: white;
  margin: 0 24px;
  @media (max-width: 575px) {
    font-size: 12px;
  }
}
.nav-right-divider {
  margin-right: 11px;
  height: 16px !important;
}
@media (max-width: 768px) {
  .countrySelectMenu.dropdown-menu {
    min-width: unset;
    max-width: 100%;
  }
}
@media (max-width: 575px) {
  .topbar-text {
    margin: 0 10px;
  }
  .nav-right-divider {
    margin-right: 0;
  }
}
</style>
