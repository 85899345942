import Vue from "vue";
import Vuex from "vuex";
import user from "./user.module";
import search from "./search.module";
import listing from "./listing.module";
import reservation from "./reservation.module";
import content from "./content.module";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const state = {
  tokenID: null,
  verifyCode: null,
  verifyEmail: null,
  verifyPhone: null,
  keepLogin: false,
  dashboardType: null,
  verifyForListing: null,

  doneRegister: false,
  accountType: null,
  register_emailAddress: null,
  register_mobileNumber: null,
  previousPageName: null,

  hostURL: process.env.VUE_APP_BASE_URL_IMAGE,

  //not used

  register_fullName: null,
  register_pw: null,

  register_countryCode: null,
  vehicleInstalledColor: null,
  vehicleOptionColor: null,
  vehicleColor: null,
  newVehicle: false,
  vehicleCylinders: null,

  vehicleOptionColorInterior: null,
  vehicleBodyStyle: null,
  vehicleOriginCountry: null,

  vehicleSeatMaterial: null,
  vehicleStandEquipments: null,
  vehicleRealStandEquipments: null,

  vehicleRealFactoryOption: null,
  vehicleOptionsSelect: null,
  hasModifications: null,

  //Vehicle Modifications
  stereo: "",
  wheelsTires: "",
  suspension: "",
  exhaust: "",
  performance: "",
  modifications: null,
  modificationsQuestions: [],
  //vehicle images
  frontImage: null,
  rearImage: null,
  driverImage: null,
  passengerImage: null,
  dashboardImage: null,
  seatingImage: null,

  //vehicle condition
  front_condition: [],
  driverSide_condition: [],
  rear_condition: [],
  passengerSide: [],
  windowsLight: [],
  interior_damage: [],
  dashboardLight: [],
  mechanical_issues: [],
  mechanical_damage_describe: null,
  //seller info

  seller_location: null,
  seller_country: null,
  seller_city: null,
  seller_province: null,
  seller_postcode: null,
  seller_info_agreement: false,
  //delivery country
  deliveryCountry: null,
  // open signIn Modal
  signModalType: "SIGN_IN" //[SIGN_IN, REGISTER]
};

const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage
    })
  ],
  modules: { content, user, search, reservation, listing },
  state,
  getters: {
    tokenID: state => {
      return state.tokenID;
    },
    verifyCode: state => {
      return state.verifyCode;
    },
    verifyEmail: state => {
      return state.verifyEmail;
    },
    verifyPhone: state => {
      return state.verifyPhone;
    },
    keepLogin: state => {
      return state.keepLogin;
    },

    //vehicle info

    verifyForListing: state => {
      return state.verifyForListing;
    },
    doneRegister: state => {
      return state.doneRegister;
    },
    hostURL: state => {
      return state.hostURL;
    },
    previousPageName: state => {
      return state.previousPageName;
    },
    accountType: state => {
      return state.accountType;
    },

    vehicleInstalledColor: state => {
      return state.vehicleInstalledColor;
    },
    vehicleOptionColor: state => {
      return state.vehicleOptionColor;
    },

    vehicleCylinders: state => {
      return state.vehicleCylinders;
    },

    vehicleOptionColorInterior: state => {
      return state.vehicleOptionColorInterior;
    },

    hasModifications: state => {
      return state.hasModifications;
    },
    vehicleBodyStyle: state => {
      return state.vehicleBodyStyle;
    },

    vehicleSeatMaterial: state => {
      return state.vehicleSeatMaterial;
    },
    vehicleRealStandEquipments: state => {
      return state.vehicleRealStandEquipments;
    },

    vehicleRealFactoryOption: state => {
      return state.vehicleRealFactoryOption;
    },
    vehicleOptionsSelect: state => {
      return state.vehicleOptionsSelect;
    },
    vehicleOriginCountry: state => {
      return state.vehicleOriginCountry;
    },

    //Vehicle Modifications
    stereo: state => {
      return state.stereo;
    },
    wheelsTires: state => {
      return state.wheelsTires;
    },
    suspension: state => {
      return state.suspension;
    },
    exhaust: state => {
      return state.exhaust;
    },
    performance: state => {
      return state.performance;
    },
    modifications: state => {
      return state.modifications;
    },
    modificationsQuestions: state => {
      return state.modificationsQuestions;
    },
    //vehich images
    frontImage: state => {
      return state.frontImage;
    },
    rearImage: state => {
      return state.rearImage;
    },
    driverImage: state => {
      return state.driverImage;
    },
    passengerImage: state => {
      return state.passengerImage;
    },
    dashboardImage: state => {
      return state.dashboardImage;
    },
    seatingImage: state => {
      return state.seatingImage;
    },

    //vehicle condition
    front_condition: state => {
      return state.front_condition;
    },
    driverSide_condition: state => {
      return state.driverSide_condition;
    },
    rear_condition: state => {
      return state.rear_condition;
    },
    passengerSide: state => {
      return state.passengerSide;
    },
    windowsLight: state => {
      return state.windowsLight;
    },
    interior_damage: state => {
      return state.interior_damage;
    },
    dashboardLight: state => {
      return state.dashboardLight;
    },
    mechanical_issues: state => {
      return state.mechanical_issues;
    },
    mechanical_damage_describe: state => {
      return state.mechanical_damage_describe;
    },
    //seller info
    register_fullName: state => {
      return state.register_fullName;
    },
    register_countryCode: state => {
      return state.register_countryCode;
    },
    register_emailAddress: state => {
      return state.register_emailAddress;
    },
    register_pw: state => {
      return state.register_pw;
    },
    register_mobileNumber: state => {
      return state.register_mobileNumber;
    },
    seller_location: state => {
      return state.seller_location;
    },
    seller_country: state => {
      return state.seller_country;
    },
    seller_city: state => {
      return state.seller_city;
    },
    seller_province: state => {
      return state.seller_province;
    },
    seller_postcode: state => {
      return state.seller_postcode;
    },
    seller_info_agreement: state => {
      return state.seller_info_agreement;
    },

    //delivery Country
    deliveryCountry: state => {
      return state.deliveryCountry;
    },
    // get sign In modal type
    signInType: state => state.signModalType,
    currencyFormatter: (state, getters) => (payload = {value: 0, currency: 'CAD'}) => {
      return new Intl.NumberFormat(getters.currencyCulture, {
        style: "currency",
        currency: ['XAF','XOF'].includes(payload.currency) ? "FCFA" : payload.currency,
        minimumFractionDigits: payload.value % 1 != 0 ? 2 : 0,
      }).format(payload.value);
    },
    dashboardType: (state) => state.dashboardType
  },

  actions: {
    tokenID(context, tokenID) {
      context.commit("tokenID", tokenID);
    },
    verifyCode(context, verifyCode) {
      context.commit("verifyCode", verifyCode);
    },
    verifyEmail(context, verifyEmail) {
      context.commit("verifyEmail", verifyEmail);
    },
    verifyPhone(context, verifyPhone) {
      context.commit("verifyPhone", verifyPhone);
    },
    keepLogin(context, keepLogin) {
      context.commit("keepLogin", keepLogin);
    },

    //vehicle info

    doneRegister(context, value) {
      context.commit("doneRegister", value);
    },
    verifyForListing(context, verifyForListing) {
      context.commit("verifyForListing", verifyForListing);
    },
    hostURL(context, hostURL) {
      context.commit("hostURL", hostURL);
    },
    previousPageName(context, value) {
      context.commit("previousPageName", value);
    },
    accountType(context, accountType) {
      context.commit("accountType", accountType);
    },

    vehicleInstalledColor(context, value) {
      context.commit("vehicleInstalledColor", value);
    },
    vehicleOptionColor(context, value) {
      context.commit("vehicleOptionColor", value);
    },

    vehicleCylinders(context, vehicleCylinders) {
      context.commit("vehicleCylinders", vehicleCylinders);
    },

    vehicleOptionColorInterior(context, value) {
      context.commit("vehicleOptionColorInterior", value);
    },

    hasModifications(context, hasModifications) {
      context.commit("hasModifications", hasModifications);
    },
    vehicleBodyStyle(context, vehicleBodyStyle) {
      context.commit("vehicleBodyStyle", vehicleBodyStyle);
    },

    vehicleSeatMaterial(context, value) {
      context.commit("vehicleSeatMaterial", value);
    },
    vehicleRealStandEquipments(context, value) {
      context.commit("vehicleRealStandEquipments", value);
    },

    vehicleRealFactoryOption(context, value) {
      context.commit("vehicleRealFactoryOption", value);
    },
    vehicleOptionsSelect(context, value) {
      context.commit("vehicleOptionsSelect", value);
    },
    vehicleOriginCountry(context, value) {
      context.commit("vehicleOriginCountry", value);
    },

    //Vehicle Modifications
    stereo(context, stereo) {
      context.commit("stereo", stereo);
    },
    wheelsTires(context, wheelsTires) {
      context.commit("wheelsTires", wheelsTires);
    },
    suspension(context, suspension) {
      context.commit("suspension", suspension);
    },
    exhaust(context, exhaust) {
      context.commit("exhaust", exhaust);
    },
    performance(context, performance) {
      context.commit("performance", performance);
    },
    modifications(context, value) {
      context.commit("modifications", value);
    },
    modificationsQuestions(context, value) {
      context.commit("modificationsQuestions", value);
    },
    //images
    frontImage(context, frontImage) {
      context.commit("frontImage", frontImage);
    },
    rearImage(context, rearImage) {
      context.commit("rearImage", rearImage);
    },
    dashboardImage(context, dashboardImage) {
      context.commit("dashboardImage", dashboardImage);
    },
    driverImage(context, driverImage) {
      context.commit("driverImage", driverImage);
    },
    passengerImage(context, passengerImage) {
      context.commit("performance", passengerImage);
    },
    seatingImage(context, seatingImage) {
      context.commit("seatingImage", seatingImage);
    },

    //vehicle condition
    front_condition(context, front_condition) {
      context.commit("front_condition", front_condition);
    },
    driverSide_condition(context, driverSide_condition) {
      context.commit("driverSide_condition", driverSide_condition);
    },
    rear_condition(context, rear_condition) {
      context.commit("rear_condition", rear_condition);
    },
    passengerSide(context, passengerSide) {
      context.commit("passengerSide", passengerSide);
    },
    windowsLight(context, windowsLight) {
      context.commit("windowsLight", windowsLight);
    },
    interior_damage(context, interior_damage) {
      context.commit("interior_damage", interior_damage);
    },
    dashboardLight(context, dashboardLight) {
      context.commit("dashboardLight", dashboardLight);
    },
    mechanical_issues(context, mechanical_issues) {
      context.commit("mechanical_issues", mechanical_issues);
    },
    mechanical_damage_describe(context, mechanical_damage_describe) {
      context.commit("mechanical_damage_describe", mechanical_damage_describe);
    },
    //seller info
    register_fullName(context, register_fullName) {
      context.commit("register_fullName", register_fullName);
    },
    register_countryCode(context, register_countryCode) {
      context.commit("register_countryCode", register_countryCode);
    },
    register_emailAddress(context, register_emailAddress) {
      context.commit("register_emailAddress", register_emailAddress);
    },
    register_pw(context, value) {
      context.commit("register_pw", value);
    },
    register_mobileNumber(context, register_mobileNumber) {
      context.commit("register_mobileNumber", register_mobileNumber);
    },
    seller_location(context, seller_location) {
      context.commit("seller_location", seller_location);
    },
    seller_country(context, seller_country) {
      context.commit("seller_country", seller_country);
    },
    seller_city(context, seller_city) {
      context.commit("seller_city", seller_city);
    },
    seller_province(context, seller_province) {
      context.commit("seller_province", seller_province);
    },
    seller_postcode(context, seller_postcode) {
      context.commit("seller_postcode", seller_postcode);
    },
    seller_info_agreement(context, seller_info_agreement) {
      context.commit("seller_info_agreement", seller_info_agreement);
    },
    //delivery Country
    deliveryCountry(context, deliveryCountry) {
      context.commit("deliveryCountry", deliveryCountry);
    },
    //delivery Country
    setSignInModalType(context, type = "SIGN_IN") {
      context.commit("signInModalType", type);
    },
    showToast(context, payload) {
      const options = {
        position: "top-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: false,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        ...payload
      };
      this._vm.$toast(payload.content, options);
    },
    setDashboardType(context, type = "SellerDashboard") {
      context.commit("setDashboardType", type);
    },

  },

  mutations: {
    tokenID(state, tokenID) {
      state.tokenID = tokenID;
    },
    verifyCode(state, verifyCode) {
      state.verifyCode = verifyCode;
    },
    verifyEmail(state, verifyEmail) {
      state.verifyEmail = verifyEmail;
    },
    verifyPhone(state, verifyPhone) {
      state.verifyPhone = verifyPhone;
    },
    keepLogin(state, keepLogin) {
      state.keepLogin = keepLogin;
    },

    //vehicle info

    doneRegister(state, value) {
      state.doneRegister = value;
    },
    verifyForListing(state, verifyForListing) {
      state.verifyForListing = verifyForListing;
    },
    hostURL(state, hostURL) {
      state.hostURL = hostURL;
    },
    previousPageName(state, value) {
      state.previousPageName = value;
    },
    accountType(state, accountType) {
      state.accountType = accountType;
    },

    vehicleInstalledColor(state, value) {
      state.vehicleInstalledColor = value;
    },
    vehicleOptionColor(state, value) {
      state.vehicleOptionColor = value;
    },

    vehicleCylinders(state, vehicleCylinders) {
      state.vehicleCylinders = vehicleCylinders;
    },

    vehicleOptionColorInterior(state, value) {
      state.vehicleOptionColorInterior = value;
    },

    hasModifications(state, hasModifications) {
      state.hasModifications = hasModifications;
    },
    vehicleBodyStyle(state, vehicleBodyStyle) {
      state.vehicleBodyStyle = vehicleBodyStyle;
    },

    vehicleSeatMaterial(state, value) {
      state.vehicleSeatMaterial = value;
    },

    vehicleRealStandEquipments(state, value) {
      state.vehicleRealStandEquipments = value;
    },

    vehicleRealFactoryOption(state, value) {
      state.vehicleRealFactoryOption = value;
    },
    vehicleOptionsSelect(state, value) {
      state.vehicleOptionsSelect = value;
    },
    vehicleOriginCountry(state, value) {
      state.vehicleOriginCountry = value;
    },

    //Vehicle Modifications
    stereo(state, stereo) {
      state.stereo = stereo;
    },
    wheelsTires(state, wheelsTires) {
      state.wheelsTires = wheelsTires;
    },
    suspension(state, suspension) {
      state.suspension = suspension;
    },
    exhaust(state, exhaust) {
      state.exhaust = exhaust;
    },
    performance(state, performance) {
      state.performance = performance;
    },
    modifications(state, value) {
      state.modifications = value;
    },
    modificationsQuestions(state, value) {
      state.modificationsQuestions = value;
    },
    //images

    frontImage(state, frontImage) {
      state.frontImage = frontImage;
    },
    rearImage(state, rearImage) {
      state.rearImage = rearImage;
    },
    driverImage(state, driverImage) {
      state.driverImage = driverImage;
    },
    passengerImage(state, passengerImage) {
      state.passengerImage = passengerImage;
    },
    dashboardImage(state, dashboardImage) {
      state.dashboardImage = dashboardImage;
    },
    seatingImage(state, seatingImage) {
      state.seatingImage = seatingImage;
    },

    //vehicle condition
    front_condition(state, front_condition) {
      state.front_condition = front_condition;
    },
    driverSide_condition(state, driverSide_condition) {
      state.driverSide_condition = driverSide_condition;
    },
    rear_condition(state, rear_condition) {
      state.rear_condition = rear_condition;
    },
    passengerSide(state, passengerSide) {
      state.passengerSide = passengerSide;
    },
    windowsLight(state, windowsLight) {
      state.windowsLight = windowsLight;
    },
    interior_damage(state, interior_damage) {
      state.interior_damage = interior_damage;
    },
    dashboardLight(state, dashboardLight) {
      state.dashboardLight = dashboardLight;
    },
    mechanical_issues(state, mechanical_issues) {
      state.mechanical_issues = mechanical_issues;
    },
    mechanical_damage_describe(state, mechanical_damage_describe) {
      state.mechanical_damage_describe = mechanical_damage_describe;
    },
    //seller info
    register_fullName(state, register_fullName) {
      state.register_fullName = register_fullName;
    },
    register_countryCode(state, register_countryCode) {
      state.register_countryCode = register_countryCode;
    },
    register_emailAddress(state, register_emailAddress) {
      state.register_emailAddress = register_emailAddress;
    },
    register_pw(state, value) {
      state.register_pw = value;
    },
    register_mobileNumber(state, register_mobileNumber) {
      state.register_mobileNumber = register_mobileNumber;
    },
    seller_location(state, seller_location) {
      state.seller_location = seller_location;
    },
    seller_country(state, seller_country) {
      state.seller_country = seller_country;
    },
    seller_city(state, seller_city) {
      state.seller_city = seller_city;
    },
    seller_province(state, seller_province) {
      state.seller_province = seller_province;
    },
    seller_postcode(state, seller_postcode) {
      state.seller_postcode = seller_postcode;
    },
    seller_info_agreement(state, seller_info_agreement) {
      state.seller_info_agreement = seller_info_agreement;
    },
    //delivery Country
    deliveryCountry(state, deliveryCountry) {
      state.deliveryCountry = deliveryCountry;
    },
    //set signIn Modal Type
    signInModalType(state, type) {
      state.signModalType = type;
    },
    setDashboardType(state, type = 'setDashboardType'){
      state.dashboardType = type;
    }
  }
});

export default store;
