<template>
  <div class="bg-white text-center">
    <b-modal
      id="logoutAlert"
      hide-header
      hide-footer
      centered
      :no-close-on-backdrop="true"
    >
      <template #default="{ hide }">
        <div class="m-auto text-center p-5">
          <vac :end-time="new Date().getTime() + 60 * 1000" @finish="finish()">
            <template v-slot:process="{ timeObj }">
              <h3>
                {{ `You will be logged out within ${timeObj.s} seconds!` }}
              </h3>
            </template>
            <template v-slot:finish>
              <h3>You are now logout!</h3>
            </template>
          </vac>
        </div>

        <b-row class="m-auto text-center">
          <b-col class="text-right">
            <form @submit.prevent="reflashTokenSubmit">
              <b-button size="sm" variant="success" @click="hide()" class="m-2">
                <!-- v-on:click="reflashToken" -->
                Keep me login
              </b-button>
            </form>
          </b-col>

          <b-col class="text-left">
            <b-button
              size="sm"
              variant="danger"
              @click="hide()"
              v-on:click="signOutClick"
              class="m-2"
            >
              I want logout
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "AutoLogout",
  components: {},
  data() {
    return {
      msgBox: "",
      events: ["click", "mousemove", "mousedown", "scroll", "keypress", "load"],
      warningTimer: null,
      logoutTimer: null,
      reflashToken: localStorage.getItem("reflashToken")
    };
  },
  mounted() {
    this.events.forEach(function(event) {
      window.addEventListener(event, this.resetTimer);
    }, this);

    this.setTimers();
  },
  computed: {},
  methods: {
    setTimers() {
      //set up auto logout message timer (mins * sec * 1000)
      this.warningTimer = setTimeout(this.warningMessage, 10 * 60 * 1000);
    },

    warningMessage() {
      this.$bvModal.show("logoutAlert");
    },

    resetTimer() {
      clearTimeout(this.warningTimer);
      this.setTimers();
    },
    signOutClick() {
      this.sendToken();
      localStorage.clear();
      this.$store.dispatch("user", null);
      window.location.assign("/");
    },
    finish() {
      this.signOutClick();
    },
    async sendToken() {
      await axios
        .post(
          "SP/post/sp_Logout",
          {
            TokenId: localStorage.getItem("token")
          },
          { timeout: 3000 }
        )
        .catch(error => {
          console.log(error);
        });
    }
    // async methodSubmit() {
    // const response = await axios.post("", {
    //     reflashToken: localStorage.getItem("reflashToken"),
    //     token: localStorage.getItem("Token")
    // });
    // localStorage.setItem("refreshToken", response.data.refreshToken);
    // }
  }
};
</script>
