export default {
  state: {
    //listing plan var
    listingPlan: [],
    listingPlanID: 2,
    listingPlanName: null,
    listingPlanPrice: null,
    listingAgreement: null,
    //car card 17 Datapoints
    vin: null,
    vehicleName: null,
    vehicleStyle: null,
    vehicleStyleId: null,

    vehicleBodyType: null,
    vehicleTrim: null,
    vehicleEngine: null,
    vehicleEngineDisplacement: null,
    vehicleDrivetrain: null,
    vehicleTransmission: null,
    vehicleFuelType: null,

    vehicleDoors: null,
    hasDoorInfo: false,
    vehicleSeats: null,
    hasSeatInfo: false,

    vehicleMileage: null,
    mileageUnit: "km",

    vehicleNumberOfKeys: null,

    vehicleColors: [],
    vehicleColor: null,
    vehicleColorInterior: null,

    //basic details

    listingStockID: null,
    detailPageShowMore: [],

    //history
    vehicleHistory: [],
    vehicleUsage: [],

    //photo
    vehicleImageList: [],
    vehiclePrimaryImage: null,
    vehicleImagesUploaded: false,

    //price
    vehiclePrice: null,

    //location
    vehicleLocation: null,
    vehicleLocation2: null,
    vehicleCity: null,
    vehicleProvince: null,
    vehiclePostal: null,

    vehicleContactFirstName: null,
    vehicleContactLastName: null,
    vehicleContactMobile: null,
    vehicleContactMobileCountryCode: null,
    vehicleContactEmail: null,

    vehicleSameContact: false,
    vehicleSameAddress: false,

    //condition
    questions: null,
    countSelects: null,
    selectedValues: []
  },
  getters: {
    //listing plan
    listingPlan: state => {
      return state.listingPlan;
    },
    listingPlanID: state => {
      return state.listingPlanID;
    },
    listingPlanName: state => {
      return state.listingPlanName;
    },
    listingPlanPrice: state => {
      return state.listingPlanPrice;
    },
    listingAgreement: state => {
      return state.listingAgreement;
    },
    //car card
    vin: state => {
      return state.vin;
    },
    vehicleName: state => {
      return state.vehicleName;
    },
    vehicleTrim: state => {
      return state.vehicleTrim;
    },
    vehicleEngine: state => {
      return state.vehicleEngine;
    },
    vehicleFuelType: state => {
      return state.vehicleFuelType;
    },
    vehicleTransmission: state => {
      return state.vehicleTransmission;
    },
    vehicleDrivetrain: state => {
      return state.vehicleDrivetrain;
    },
    vehicleSeats: state => {
      return state.vehicleSeats;
    },
    hasSeatInfo: state => {
      return state.hasSeatInfo;
    },
    vehicleDoors: state => {
      return state.vehicleDoors;
    },
    hasDoorInfo: state => {
      return state.hasDoorInfo;
    },
    vehicleMileage: state => {
      return state.vehicleMileage;
    },
    vehicleNumberOfKeys: state => {
      return state.vehicleNumberOfKeys;
    },
    vehicleColor: state => {
      return state.vehicleColor;
    },
    vehicleColors: state => {
      return state.vehicleColors;
    },
    vehicleColorInterior: state => {
      return state.vehicleColorInterior;
    },
    vehicleStyleId: state => {
      return state.vehicleStyleId;
    },
    vehicleBodyType: state => {
      return state.vehicleBodyType;
    },
    vehicleStyle: state => {
      return state.vehicleStyle;
    },

    //basic details
    vehicleEngineDisplacement: state => {
      return state.vehicleEngineDisplacement;
    },
    listingStockID: state => {
      return state.listingStockID;
    },
    mileageUnit: state => {
      return state.mileageUnit;
    },
    detailPageShowMore: state => {
      return state.detailPageShowMore;
    },

    //history
    vehicleHistory: state => {
      return state.vehicleHistory;
    },
    vehicleUsage: state => {
      return state.vehicleUsage;
    },
    //photo
    vehicleImageList: state => {
      return state.vehicleImageList;
    },
    vehiclePrimaryImage: state => {
      return state.vehiclePrimaryImage;
    },
    vehicleImagesUploaded: state => {
      return state.vehicleImagesUploaded;
    },

    //price
    vehiclePrice: state => {
      return state.vehiclePrice;
    },

    //location
    vehicleLocation: state => {
      return state.vehicleLocation;
    },
    vehicleProvince: state => {
      return state.vehicleProvince;
    },
    vehicleCity: state => {
      return state.vehicleCity;
    },
    vehicleLocation2: state => {
      return state.vehicleLocation2;
    },
    vehiclePostal: state => {
      return state.vehiclePostal;
    },

    vehicleContactFirstName: state => {
      return state.vehicleContactFirstName;
    },
    vehicleContactLastName: state => {
      return state.vehicleContactLastName;
    },
    vehicleContactMobileCountryCode: state => {
      return state.vehicleContactMobileCountryCode;
    },
    vehicleContactMobile: state => {
      return state.vehicleContactMobile;
    },
    vehicleContactEmail: state => {
      return state.vehicleContactEmail;
    },

    vehicleSameContact: state => {
      return state.vehicleSameContact;
    },
    vehicleSameAddress: state => {
      return state.vehicleSameAddress;
    },

    //condition
    questions: state => {
      return state.questions;
    },
    countSelects: state => {
      return state.countSelects;
    },
    selectedValues: state => {
      return state.selectedValues;
    }
  },
  actions: {
    //listing plan var
    listingPlan(context, value) {
      context.commit("listingPlan", value);
    },
    listingPlanID(context, value) {
      context.commit("listingPlanID", value);
    },
    listingPlanName(context, value) {
      context.commit("listingPlanName", value);
    },
    listingPlanPrice(context, value) {
      context.commit("listingPlanPrice", value);
    },
    listingAgreement(context, value) {
      context.commit("listingAgreement", value);
    },
    vehicleFuelType(context, vehicleFuelType) {
      context.commit("vehicleFuelType", vehicleFuelType);
    },
    vehicleTransmission(context, vehicleTransmission) {
      context.commit("vehicleTransmission", vehicleTransmission);
    },
    //car card

    vin(context, vin) {
      context.commit("vin", vin);
    },
    vehicleName(context, vehicleName) {
      context.commit("vehicleName", vehicleName);
    },
    vehicleTrim(context, vehicleTrim) {
      context.commit("vehicleTrim", vehicleTrim);
    },
    vehicleEngine(context, vehicleEngine) {
      context.commit("vehicleEngine", vehicleEngine);
    },
    vehicleDrivetrain(context, vehicleDrivetrain) {
      context.commit("vehicleDrivetrain", vehicleDrivetrain);
    },
    vehicleSeats(context, value) {
      context.commit("vehicleSeats", value);
    },
    hasSeatInfo(context, value) {
      context.commit("hasSeatInfo", value);
    },
    vehicleDoors(context, value) {
      context.commit("vehicleDoors", value);
    },
    hasDoorInfo(context, value) {
      context.commit("hasDoorInfo", value);
    },
    vehicleMileage(context, vehicleMileage) {
      context.commit("vehicleMileage", vehicleMileage);
    },
    vehicleNumberOfKeys(context, vehicleNumberOfKeys) {
      context.commit("vehicleNumberOfKeys", vehicleNumberOfKeys);
    },
    vehicleColor(context, vehicleColor) {
      context.commit("vehicleColor", vehicleColor);
    },
    vehicleColors(context, vehicleColor) {
      context.commit("vehicleColors", vehicleColor);
    },
    vehicleColorInterior(context, vehicleColorInterior) {
      context.commit("vehicleColorInterior", vehicleColorInterior);
    },
    vehicleStyleId(context, value) {
      context.commit("vehicleStyleId", value);
    },
    vehicleBodyType(context, value) {
      context.commit("vehicleBodyType", value);
    },
    vehicleStyle(context, value) {
      context.commit("vehicleStyle", value);
    },
    //basic details
    vehicleEngineDisplacement(context, value) {
      context.commit("vehicleEngineDisplacement", value);
    },
    listingStockID(context, value) {
      context.commit("listingStockID", value);
    },
    mileageUnit(context, value) {
      context.commit("mileageUnit", value);
    },

    detailPageShowMore(context, value) {
      context.commit("detailPageShowMore", value);
    },
    //history
    vehicleHistory(context, value) {
      context.commit("vehicleHistory", value);
    },
    vehicleUsage(context, value) {
      context.commit("vehicleUsage", value);
    },
    //photo
    vehicleImageList(context, value) {
      context.commit("vehicleImageList", value);
    },
    vehiclePrimaryImage(context, value) {
      context.commit("vehiclePrimaryImage", value);
    },
    vehicleImagesUploaded(context, value) {
      context.commit("vehicleImagesUploaded", value);
    },

    //price
    vehiclePrice(context, vehiclePrice) {
      context.commit("vehiclePrice", vehiclePrice);
    },

    //location
    vehicleLocation(context, vehicleLocation) {
      context.commit("vehicleLocation", vehicleLocation);
    },
    vehicleProvince(context, vehicleProvince) {
      context.commit("vehicleProvince", vehicleProvince);
    },
    vehicleCity(context, vehicleCity) {
      context.commit("vehicleCity", vehicleCity);
    },
    vehicleLocation2(context, vehicleLocation2) {
      context.commit("vehicleLocation2", vehicleLocation2);
    },
    vehiclePostal(context, value) {
      context.commit("vehiclePostal", value);
    },

    vehicleContactFirstName(context, value) {
      context.commit("vehicleContactFirstName", value);
    },
    vehicleContactLastName(context, value) {
      context.commit("vehicleContactLastName", value);
    },
    vehicleContactMobileCountryCode(context, value) {
      context.commit("vehicleContactMobileCountryCode", value);
    },
    vehicleContactMobile(context, value) {
      context.commit("vehicleContactMobile", value);
    },
    vehicleContactEmail(context, value) {
      context.commit("vehicleContactEmail", value);
    },

    vehicleSameContact(context, value) {
      context.commit("vehicleSameContact", value);
    },
    vehicleSameAddress(context, value) {
      context.commit("vehicleSameAddress", value);
    },

    //condition
    questions(context, value) {
      context.commit("questions", value);
    },
    countSelects(context, value) {
      context.commit("countSelects", value);
    },
    selectedValues(context, value) {
      context.commit("selectedValues", value);
    }
  },
  mutations: {
    //listing plan var
    listingPlan(state, value) {
      state.listingPlan = value;
    },
    listingPlanID(state, value) {
      state.listingPlanID = value;
    },
    listingPlanName(state, value) {
      state.listingPlanName = value;
    },
    listingPlanPrice(state, value) {
      state.listingPlanPrice = value;
    },
    listingAgreement(state, value) {
      state.listingAgreement = value;
    },
    //car card

    vin(state, vin) {
      state.vin = vin;
    },
    vehicleName(state, vehicleName) {
      state.vehicleName = vehicleName;
    },
    vehicleTrim(state, vehicleTrim) {
      state.vehicleTrim = vehicleTrim;
    },
    vehicleEngine(state, vehicleEngine) {
      state.vehicleEngine = vehicleEngine;
    },
    vehicleFuelType(state, vehicleFuelType) {
      state.vehicleFuelType = vehicleFuelType;
    },
    vehicleTransmission(state, vehicleTransmission) {
      state.vehicleTransmission = vehicleTransmission;
    },
    vehicleDrivetrain(state, vehicleDrivetrain) {
      state.vehicleDrivetrain = vehicleDrivetrain;
    },
    vehicleSeats(state, value) {
      state.vehicleSeats = value;
    },
    hasSeatInfo(state, value) {
      state.hasSeatInfo = value;
    },
    vehicleDoors(state, value) {
      state.vehicleDoors = value;
    },
    hasDoorInfo(state, value) {
      state.hasDoorInfo = value;
    },
    vehicleMileage(state, vehicleMileage) {
      state.vehicleMileage = vehicleMileage;
    },
    vehicleNumberOfKeys(state, vehicleNumberOfKeys) {
      state.vehicleNumberOfKeys = vehicleNumberOfKeys;
    },
    vehicleColor(state, vehicleColor) {
      state.vehicleColor = vehicleColor;
    },
    vehicleColors(state, vehicleColor) {
      state.vehicleColors = vehicleColor;
    },
    vehicleColorInterior(state, vehicleColorInterior) {
      state.vehicleColorInterior = vehicleColorInterior;
    },
    vehicleStyleId(state, value) {
      state.vehicleStyleId = value;
    },
    vehicleBodyType(state, value) {
      state.vehicleBodyType = value;
    },
    vehicleStyle(state, value) {
      state.vehicleStyle = value;
    },
    //basic details
    vehicleEngineDisplacement(state, value) {
      state.vehicleEngineDisplacement = value;
    },
    listingStockID(state, value) {
      state.listingStockID = value;
    },
    mileageUnit(state, value) {
      state.mileageUnit = value;
    },
    detailPageShowMore(state, value) {
      state.detailPageShowMore = value;
    },
    //history
    vehicleHistory(state, value) {
      state.vehicleHistory = value;
    },
    vehicleUsage(state, value) {
      state.vehicleUsage = value;
    },
    //photo
    vehicleImageList(state, value) {
      state.vehicleImageList = value;
    },
    vehiclePrimaryImage(state, value) {
      state.vehiclePrimaryImage = value;
    },
    vehicleImagesUploaded(state, value) {
      state.vehicleImagesUploaded = value;
    },

    //peice
    vehiclePrice(state, vehiclePrice) {
      state.vehiclePrice = vehiclePrice;
    },

    //location
    vehicleLocation(state, vehicleLocation) {
      state.vehicleLocation = vehicleLocation;
    },
    vehicleLocation2(state, vehicleLocation2) {
      state.vehicleLocation2 = vehicleLocation2;
    },
    vehicleCity(state, vehicleCity) {
      state.vehicleCity = vehicleCity;
    },
    vehicleProvince(state, vehicleProvince) {
      state.vehicleProvince = vehicleProvince;
    },
    vehiclePostal(state, value) {
      state.vehiclePostal = value;
    },

    vehicleContactLastName(state, value) {
      state.vehicleContactLastName = value;
    },
    vehicleContactFirstName(state, value) {
      state.vehicleContactFirstName = value;
    },
    vehicleContactMobileCountryCode(state, value) {
      state.vehicleContactMobileCountryCode = value;
    },
    vehicleContactMobile(state, value) {
      state.vehicleContactMobile = value;
    },
    vehicleContactEmail(state, value) {
      state.vehicleContactEmail = value;
    },

    vehicleSameContact(state, value) {
      state.vehicleSameContact = value;
    },
    vehicleSameAddress(state, value) {
      state.vehicleSameAddress = value;
    },

    //condition
    questions(state, value) {
      state.questions = value;
    },
    countSelects(state, value) {
      state.countSelects = value;
    },
    selectedValues(state, value) {
      state.selectedValues = value;
    }
  }
};
