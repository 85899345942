<template>
  <div
    id="header"
    :class="{
      buySell: isBuySell,
      'sticky-position':
        $route.path.includes('inspection-agents') ||
        $route.path.includes('freight-forwarders') ||
        $route.path.includes('customs-brokers') ||
        $route.path.includes('sales-representatives') ||
        $route.path.includes('jobs'),
    }"
  >
    <TheTopBar :isTransparent="isBuySell" :showLogo="needShowLogo" />
    <TheNavBar :isTransparent="isBuySell" />
    <!-- <div class="bg-secondary">
      <TheSearchBar
        v-if="
          $route.path !== '/' &&
            $route.path !== '/buy' &&
            $route.path !== '/sell'
        "
      />
    </div> -->
  </div>
</template>

<script>
import TheTopBar from "@/components/global/TheTopBar.vue";
import TheNavBar from "@/components/global/TheNavbar.vue";
// import TheSearchBar from "@/components/global/TheSearchBar.vue";

export default {
  name: "TheHeader",
  components: {
    TheTopBar,
    TheNavBar,
    // TheSearchBar
  },
  computed: {
    isBuySell() {
      // let path = this.$route.path;
      // let buy = this.$t("navBar.link.buy");
      // let sell = this.$t("navBar.link.sell");
      // if (path == buy || path == sell) {
      //   return true;
      // }
      if (this.$route.name == "Buy" || this.$route.name == "Sell") {
        return true;
      }
      return false;
    },
    needShowLogo() {
      if (this.$route.name == "RegisterPage") {
        return true;
      }
      return false;
    },
  },
};
</script>
<style scoped>
.buySell {
  z-index: 10;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
</style>
