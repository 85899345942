<template>
  <!-- Start Footer -->
  <footer id="footer" class="footer container-fluid">
    <b-container>
      <div class="row text-light">
        <div class="col-12 text-left has-order">
          <router-link :to="{ name: 'Home' }" class="navbar-brand">
            <img src="@/assets/img/Pash-logo.svg" alt="pash" title="logo" />
          </router-link>

          <!--
        <ul class="list-inline mt-3">
          <li class="list-inline-item mr-4">
            <ShareNetwork
              network="twitter"
              :url="sharing.url"
              :title="sharing.title"
              :description="sharing.description"
              :quote="sharing.quote"
            >
              <a class="text-waterBlue"><i class="fa fa-twitter fa-lg"></i></a>
            </ShareNetwork>
          </li>
          <li class="list-inline-item mr-4">
            <ShareNetwork
              network="facebook"
              :url="sharing.url"
              :title="sharing.title"
              :description="sharing.description"
              :quote="sharing.quote"
            >
              <a class="text-seaBlue"><i class="fa fa-facebook fa-lg"></i></a>
            </ShareNetwork>
          </li>
          <li class="list-inline-item mr-4">
            <ShareNetwork
              network="email"
              :url="sharing.url"
              :title="sharing.title"
              :description="sharing.description"
              :quote="sharing.quote"
            >
              <a class="text-danger"><i class="fa fa-google-plus fa-lg"></i></a>
            </ShareNetwork>
          </li>
        </ul>
-->

          <p class="about-pash-wrapper">
            <small class="about-pash">
              <!-- {{ $t("footer.label.contentBrief") }} -->
              {{ $t("Footer.Footer.CommittedToOffering") }}
            </small>
          </p>
        </div>

        <b-col class="order-1 order-lg-2 mb-xl-0 footer-links">
          <b-row>
            <b-col class="col-6 mb-4 col-md mb-lg-0">
              <h6>{{ $t("Footer.Footer.Buyers") }}</h6>
              <b-link :to="{ name: 'Buy' }" @click="goTo('searchAnchor')">
                <p>{{ $t("Footer.Footer.SearchCars") }}</p>
              </b-link>
              <b-link :to="{ name: 'Buy' }" @click="goTo('servicesAnchor')">
                <p>{{ $t("Footer.Footer.HowToBuy") }}</p>
              </b-link>
              <b-link :to="{ name: 'Buy' }" @click="goTo('advantagesAnchor')">
                <p>{{ $t("Footer.Footer.Advantages") }}</p>
              </b-link>
              <b-link :to="{ name: 'ContactReps' }">
                <p>{{ $t("Footer.Footer.SalesRepresentative") }}</p>
              </b-link>
            </b-col>
            <b-col class="col-6 mb-4 col-md mb-lg-0">
              <h6>{{ $t("Footer.Footer.Seelers") }}</h6>
              <b-link :to="{ name: 'listingOption' }">
                <p>{{ $t("Footer.Footer.SellMyCars") }}</p>
              </b-link>
              <b-link :to="{ name: 'Sell' }" @click="goTo('whySellAnchor')">
                <p>{{ $t("Footer.Footer.WhySellWithPash") }}</p>
              </b-link>
              <b-link :to="{ name: 'ContactReps' }">
                <p>{{ $t("Footer.Footer.CarDealers") }}</p>
              </b-link>
              <b-link :to="{ name: 'ContactReps' }">
                <p>{{ $t("Footer.Footer.PrivateSellers") }}</p>
              </b-link>
              <b-link :to="{ name: 'Sell' }" @click="goTo('howItWorkAnchor')">
                <p>{{ $t("Footer.Footer.HowToSellWithPash") }}</p>
              </b-link>
            </b-col>
            <b-col class="col-6 mb-4 col-md mb-lg-0">
              <h6>{{ $t("navBar.label.workWithPASH") }}</h6>
              <b-link :to="{ name: 'Contact', params: { type: 'inspection-agents' } }">
                <p>{{ $t("navBar.label.inspectionAgents") }}</p>
              </b-link>
              <b-link
                :to="{
                  name: 'Contact',
                  params: { type: 'freight-forwarders' },
                }"
              >
                <p>{{ $t("navBar.label.freightForwarders") }}</p>
              </b-link>
              <b-link :to="{ name: 'Contact', params: { type: 'customs-brokers' } }">
                <p>{{ $t("navBar.label.customsBrokers") }}</p>
              </b-link>
              <b-link
                :to="{
                  name: 'Contact',
                  params: { type: 'sales-representatives' },
                }"
              >
                <p>{{ $t("navBar.label.salesRepresentatives") }}</p>
              </b-link>
              <b-link :to="{ name: 'Contact', params: { type: 'jobs' } }">
                <p>{{ $t("navBar.label.jobs") }}</p>
              </b-link>
            </b-col>
            <b-col class="col-6 mb-4 col-md mb-lg-0 mx-auto">
              <h6>{{ $t("Footer.Footer.Company") }}</h6>
              <b-link :to="{ name: 'Contact', params: { type: 'contact' } }">
                <p>{{ $t("Footer.Footer.AboutUs") }}</p>
              </b-link>
              <b-link :to="{ name: 'WorkwithUs' }">
                <p>{{ $t("Footer.Footer.Partners") }}</p>
              </b-link>
            </b-col>
            <!-- <b-col class="col-6 mb-4 col-lg offset-lg-0 mb-lg-0">
            <h6>{{ $t("Footer.Footer.Legal") }}</h6>
            <b-link v-b-modal.comingSoon>
              <p>{{ $t("Footer.Footer.PrivacyPolicy") }}</p>
            </b-link>
            <b-link v-b-modal.comingSoon>
              <p>{{ $t("Footer.Footer.TermsOfUse") }}</p>
            </b-link>
          </b-col> -->
          </b-row>
        </b-col>
        <!-- <b-modal centered hide-header hide-footer size="md" id="comingSoon">
        <h1 class="m-5 text-center text-half-dark">Coming Soon...</h1>
      </b-modal> -->
      </div></b-container
    >
  </footer>
  <!-- End Footer -->
</template>

<script>
export default {
  data() {
    return {
      sharing: {
        url: "https://pashmotor.com/",
        title: "Say hi to Vite! A brand new, extremely fast development setup for Vue.",
        description: "Brand new development setup created by Evan You.",
        quote: "The hot reload is so fast it's near instant. - Evan You",
      },
      text: null,
    };
  },
  methods: {
    //Retrieves the footer categories from the API, footer is pageID 179
    // async getFooterMenu() {
    //   this.loading = true;
    //   try {
    //     const response = await axios.post("SP/Post/Content_Page_Get", {
    //       Lang: "en",
    //       PageID: 179,
    //       outParentPageID: 1,
    //       outParentSlug: ""
    //     });
    //     this.loading = false;
    //     this.menu = JSON.parse(response.data.result).Table;
    //   } catch (error) {
    //     this.loading = false;
    //     this.errorMessage = error.response.data;
    //   }
    // },
    goTo(anchor) {
      document.getElementById(anchor).scrollIntoView();
    },
  },
  computed: {
    isDashboardPage() {
      if (this.$route.matched[0]) {
        if (this.$route.matched[0].path === "/dashboards") {
          return true;
        } else return false;
      }
      return false;
    },
  },
};
</script>
<style scoped lang="scss">
.footer {
  padding-top: 64px;
  background-color: #1c1c1c;
  padding-bottom: 48px;
  @media (max-width: 767px) {
    padding-top: 32px;
  }
}
.footer_side_distance {
  margin-left: 30px;
}
.footer-links a,
.about-pash {
  font-size: 14px;
}
.footer-links a {
  position: relative;
  display: block;
  width: fit-content;
  &:after {
    content: "";
    position: absolute;
    height: 1px;
    width: 0;
    bottom: -2px;
    background: #f8f9fa;
    right: 0;
    left: 0;
    margin: auto;
    transition: 0.3s;
  }
  &:hover {
    &:after {
      width: 100%;
    }
  }
}
h6 {
  margin-bottom: 24px;
  color: #f8f9fa;
  text-transform: uppercase;
  font-weight: 600;
  @media (max-width: 767px) {
    margin-bottom: 32px;
    font-weight: 500;
  }
}
.has-order {
  order: 3;
  text-align: center !important;
  border-top: 1px solid #d0d0d061;
  padding-top: 32px;
  margin-top: 16px;
  @media (max-width: 575px) {
    padding: 16px 0 0 0;
  }
}
.about-pash-wrapper {
  margin-right: auto;
  margin-left: auto;
  width: 690px;
  margin-top: 24px;
  margin-bottom: 0;
  @media (max-width: 767px) {
    width: 100%;
  }
}
.navbar-brand {
  padding-top: 0;
}
p {
  margin-bottom: 8px;
  color: #d9d9d9;
}
.col-6.col-md.col {
  padding-left: 0;
}
</style>
