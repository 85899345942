<template>
  <div class="bg-secondary">
    <b-modal
      id="signin"
      hide-footer
      hide-header
      centered
      no-stacking
      no-close-on-backdrop
      size="lg"
      @shown="checkStoreType"
      @hidden="onCloseModal"
      dialog-class="signInModal"
    >
      <template #default="{ hide }">
        <b-row>
          <b-col class="p-left d-none d-lg-block">
            <div class="fixed-background">
              <div class="text-center text-light mt-5">
                <h2>{{ $t("signIn.label.welcome") }}</h2>
              </div>
              <div class="fixed-wrap">
                <div class="fixed">
                  <div class="signIn_cover"></div>
                </div>
              </div>
            </div>
          </b-col>
          <b-col class="p-right" v-if="signInPage">
            <div>
              <b-button class="close px-2 pb-2" @click="hide()">x</b-button>
            </div>
            <div class="text-center mt-5">
              <h2>{{ $t("signIn.label.title") }}</h2>
            </div>
            <section v-if="steps === 1">
              <div class="col-md-10 m-auto">
                <div class="col-12 text-center">
                  <p class="pt-3 m-0">
                    {{ $t("signIn.label.noAccount") }}
                  </p>
                  <!-- <b-link v-b-modal.register @click="hide()">
                    {{ $t("signIn.label.createAccount") }}
                  </b-link> -->
                  <p>
                    <b-link @click="signInPage = false">
                      {{ $t("signIn.label.createAccount") }}
                    </b-link>
                  </p>
                </div>

                <label class="form-label pl-2 pt-2"
                  >{{ $t("signIn.label.email") }} /
                  {{ $t("signIn.label.phoneNumber") }}</label
                >

                <b-form-input
                  v-show="!switchInput"
                  type="text"
                  id="userEmail"
                  v-model="username"
                  class="input-pills"
                  :class="{ 'is-invalid': step && !username }"
                  :state="username ? isEmail || isMobile : null"
                  @input="focusTo(), checkEmail()"
                ></b-form-input>
                <b-input-group v-show="switchInput">
                  <VuePhoneNumberInput
                    id="userMobile"
                    class="col p-0"
                    v-model="username"
                    :default-country-code="
                      $store.getters.country && $store.getters.country.Code
                        ? $store.getters.country.Code
                        : 'CA'
                    "
                    @update="setMobilePayload($event)"
                    :translations="{
                      countrySelectorLabel: $t('Contact.countrySelectorLabel'),
                      countrySelectorError: $t('Contact.countrySelectorError'),
                      phoneNumberLabel: $t('Contact.phoneNumberLabel'),
                      example: $t('Contact.example'),
                    }"
                  />
                  <b-input-group-append>
                    <div
                      class="right-rounded border border-left-0 px-3 bg-white"
                      :class="isMobile ? 'border-success' : ''"
                    >
                      <b-icon
                        v-if="isMobile"
                        icon="check"
                        variant="success"
                        scale="2"
                        style="margin-top: 13px; margin-right: -5px"
                      ></b-icon>
                    </div>
                  </b-input-group-append>
                </b-input-group>

                <div v-show="step && !username" class="invalid-feedback">
                  {{ $t("global.message.fieldEmptyError") }}
                </div>
                <div>
                  <b v-show="availableMobile == false" class="text-danger m-0 mini-font">
                    <b-icon icon="x-circle-fill"/> {{ $t("signIn.label.mobileExist") }}
                    <b-link @click="signInPage = false"> {{ $t("signIn.label.createYourAccount") }} </b-link>
                  </b>
                  <b v-show="availableEmail == false" class="text-danger m-0 mini-font">
                    <b-icon icon="x-circle-fill"/>{{ $t("signIn.label.emailExist") }}
                    <b-link @click="signInPage = false">{{ $t("signIn.label.createYourAccount") }}</b-link>
                  </b>
                </div>
              </div>
              <div class="col-md-10 py-5 m-auto">
                <!-- :variant="
                    availableEmail || availableMobile ? 'primary' : 'outline-primary'
                  " -->
                <b-button
                  pill
                  variant="primary"
                  class="w-100"
                  :disabled="username ? !availableEmail && !availableMobile : true"
                  @click="loginSendCode()"
                >
                  {{ $t("signIn.button.continue") }}
                </b-button>
              </div>
            </section>
            <section v-if="steps === 2">
              <div class="col-10 m-auto">
                <b-link @click="steps = 1"
                  ><b> <b-icon icon="arrow-left-square"></b-icon> Back</b></b-link
                >
                <p>{{ $t("signIn.label.sendCodeToUser",{username}) }}</p>

                <b-row>
                  <b-col>
                    <label> Code </label>
                  </b-col>
                  <b-col>
                    <b-link @click="loginSendCode()">Resend the code</b-link>
                  </b-col>
                </b-row>
                <CodeInput
                  :key="componentKey"
                  :loading="false"
                  :fields="4"
                  :autoFocus="true"
                  v-on:complete="onComplete"
                />
                <b-form-checkbox v-model="$store.state.keepLogin" @change="keepLogin()">
                  {{ $t("signIn.label.rememberMe") }}
                </b-form-checkbox>
              </div>
              <div class="col-10 pt-3 m-auto">
                <b-link @click="steps = 3">{{ $t("signIn.label.useMyPasswordInstead") }}</b-link>
              </div>
              <div class="col-10 m-auto">
                <p class="text-danger">{{ errorMessage }}</p>
              </div>
            </section>
            <section v-if="steps === 3">
              <form @submit.prevent="signInSubmit" class="pb-5">
                <div class="col-md-10 m-auto">
                  <p class="py-2">
                    <b-link @click="steps = 1"
                      ><b>{{ username }}</b></b-link
                    >
                  </p>
                  <label class="form-label pl-2 pt-2">{{
                    $t("signIn.label.password")
                  }}</label>
                  <b-input-group>
                    <template #append>
                      <b-input-group-text class="bg-white border-left-0 right-rounded"
                        ><b-icon :icon="eyes" @click="showPassword()"></b-icon
                      ></b-input-group-text>
                    </template>
                    <b-form-input
                      :type="passwordField"
                      v-model="password"
                      name="password"
                      class="form-control left-rounded border-right-0"
                    ></b-form-input>
                    <div v-show="submitted && !password" class="invalid-feedback">
                      {{ $t("global.message.fieldEmptyError") }}
                    </div>
                  </b-input-group>
                </div>
                <b-row class="col-11 m-auto text-center pt-2">
                  <b-col class="m-auto p-0">
                    <b-form-checkbox
                      v-model="$store.state.keepLogin"
                      @change="keepLogin()"
                    >
                      {{ $t("signIn.label.rememberMe") }}
                    </b-form-checkbox>
                  </b-col>
                  <b-col class="m-auto p-0">
                    <b-button
                      class="border-0 p-0 bg-transparent text-primary"
                      v-b-modal.forgotPassword
                    >
                      {{ $t("signIn.label.forgotPassword") }}
                    </b-button>
                  </b-col>
                </b-row>
                <div class="col-10 pt-3 m-auto">
                  <p class="text-danger">{{ errorMessage }}</p>
                  <b-overlay :show="overlay">
                    <b-button
                      pill
                      variant="outline-primary"
                      class="w-100"
                      :disabled="overlay"
                      type="submit"
                    >
                      {{ $t("signIn.button.continue") }}
                    </b-button>
                  </b-overlay>
                </div>
              </form>
            </section>
          </b-col>
          <b-col v-else>
            <div>
              <b-button class="close px-2 pb-2" @click="hide()">x</b-button>
            </div>
            <div class="text-center my-5 pr-3">
              <h5>{{ $t("signIn.label.createAccountTitle") }}</h5>
            </div>

            <b-form-radio-group
              button-variant="outline-primary"
              size="lg"
              v-model="accountType"
              buttons
              class="pr-3 action-buttons"
              @change="hide(), selectAccountType()"
            >
              <b-form-radio :value="1" class="p-4 mx-2">{{ $t("signIn.label.buyerAccount") }}</b-form-radio>
              <b-form-radio :value="2" class="p-4 mx-2">{{ $t("signIn.label.sellerAccount") }}</b-form-radio>
            </b-form-radio-group>

            <p class="text-center pt-5">
              {{ $t("signIn.label.haveAccount") }}
              <b-link @click="signInPage = true">
                {{ $t("signIn.label.title") }}
              </b-link>
            </p>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    <TheForgotPassword />
  </div>
</template>

<script>
import TheForgotPassword from "@/components/global/TheForgotPassword.vue";
import axios from "axios";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import CodeInput from "vue-verification-code-input";
import { mapGetters } from "vuex";
import parsePhoneNumber from "libphonenumber-js";

export default {
  name: "SignIn",
  components: {
    TheForgotPassword,
    VuePhoneNumberInput,
    CodeInput,
  },
  computed: {
    ...mapGetters(["signInType"]),
    isEmail() {
      if (this.username) {
        if (this.emailFormat.test(this.username)) {
          return true;
        } else return false;
      } else return false;
    },
    isMobile() {
      // if(this.username) {
      //   if(!isNaN(this.username) && this.username.length >= 10) {return true;} else return false
      // }
      if (this.mobilePayload) {
        if (this.mobilePayload.isValid) {
          return true;
        } else return false;
      } else return false;
    },
    switchInput() {
      if (this.username) {
        if (/^[a-zA-Z]+$/.test(this.username.charAt(0))) {
          return false;
        } else {
          return true;
        }
      }
      return false;
    },
  },
  data() {
    return {
      componentKey: 0,
      username: localStorage.getItem("username"),
      password: null,
      passwordField: "password",
      eyes: "eye",
      errorMessage: null,
      submitted: false,
      overlay: false,
      response: null,
      accountType: null,
      country: "ca",
      countries: [
        { value: "ca", text: "Canada" },
        { value: "us", text: "USA" },
        { value: "mx", text: "Mexico" },
      ],
      rememberMe: false,
      emailFormat: /\S+@\S+\.\S+/,
      steps: 1,
      step: false,
      code: null,
      loginKey: null,
      mobilePayload: null,
      mobileID: null,
      availableEmail: null,
      availableMobile: null,
      signInPage: true,
    };
  },
  methods: {
    onComplete(v) {
      this.code = v;
      this.loginVerify();
    },

    async signInSubmit() {
      this.overlay = true;
      this.submitted = true;
      const { username, password } = this;
      // const { username, password, accountType } = this;
      // stop here if form is invalid
      if (!username || !password) {
        this.errorMessage = "username or password is missing";
        this.overlay = false;
        return;
      }
      // if (!accountType) {
      //   this.errorMessage = 'please enter your email or phone number';
      //   this.overlay = false;
      //   return;
      // }
      try {
        const response = await axios
          .post(
            "user/LoginVerify",
            {
              LoginKey: this.loginKey,
              Password: password,
            },
            { timeout: 3000 }
          )
          .catch((error) => {
            console.log(error);
            this.errorMessage = error.response.data
              ? error.response.data
              : "Network connection timed out, please try again";
            this.overlay = false;
          });

        this.response = response;
        localStorage.setItem("token", this.loginKey);
        this.$store.dispatch("tokenID", this.loginKey);
        this.overlay = false;
        await this.$store.dispatch("userFromApi", this.loginKey);
        this.$root.$emit("changeLang");
        if (this.$store.getters.user) {
          this.$bvModal.hide("signin");
          this.checkScroll();
        } else {
          this.errorMessage = "something wrong with get user info";
        }
      } catch (error) {
        this.errorMessage = error;
        this.overlay = false;
      }
    },
    checkScroll(){
      //check if reservation login
      if(this.$route.name === 'Reservation'){
        setTimeout(() => {
          const el = document.querySelector('#reservation-userinfo-area');
          if(el) el.scrollIntoView()
        },1000)
      }

    },
    showPassword() {
      if (this.passwordField === "password") {
        (this.passwordField = "text"), (this.eyes = "eye-slash");
      } else if (this.passwordField === "text") {
        (this.passwordField = "password"), (this.eyes = "eye");
      }
    },
    keepLogin() {
      localStorage.setItem("keepLogin", this.$store.getters.keepLogin);
    },
    async loginSendCode() {
      this.componentKey += 1;
      this.errorMessage = null;
      try {
        if (this.isEmail) {
          const response = axios.post(
            "user/Login",
            {
              Email: this.username,
              DeviceName: "edge windows 10",
            },
            { timeout: 3000 }
          );
          this.steps = 2;
          this.step = true;
          this.loginKey = (await response).data;
        } else if (this.isMobile) {
          const response = axios.post(
            "user/Login",
            {
              Mobile: this.mobilePayload.formattedNumber,
              DeviceName: "edge windows 10",
            },
            { timeout: 3000 }
          );
          this.steps = 2;
          this.step = true;
          this.loginKey = (await response).data;
        }
        axios.defaults.headers.common["accesstoken"] = this.loginKey;
      } catch (error) {
        console.log(error);
      }
    },
    async loginVerify() {
      try {
        const response = axios.post(
          "user/LoginVerify",
          {
            Code: this.code,
            LoginKey: this.loginKey,
          },
          { timeout: 3000 }
        );
        console.log(response);
        if ((await response).data) {
          // localStorage.setItem("refreshToken", this.response.data.refreshToken);
          localStorage.setItem("token", this.loginKey);
          this.overlay = false;
          await this.$store.dispatch("userFromApi", this.loginKey);
          this.$root.$emit("changeLang");
          if (this.$store.getters.user) {
            this.$bvModal.hide("signin");
            this.checkScroll();
          } else {
            this.errorMessage = "something wrong with get user info";
          }
        } else {
          this.errorMessage = "something wrong with code, please try again";
        }
      } catch (error) {
        this.errorMessage = error.response.data;
        console.log(error);
      }
    },
    focusTo() {
      if (this.switchInput && this.username) {
        if (this.username.length === 1) {
          let l = document.getElementsByTagName("input");
          for (let i = 0; i < l.length; i++) {
            if (l[i].className === "input-tel__input") {
              this.mobileID = l[i].id;
            }
          }
          document.getElementById(this.mobileID).focus();
        }
      }
    },
    async checkEmail() {
      this.availableEmail = null;
      try {
        if (this.isEmail) {
          const response = await axios.post("user/IsEmailExist", {
            Email: this.username,
          });
          this.availableEmail = response.data;
        }
      } catch (error) {
        this.availableEmail = false;
        this.errorMessage = error.response.data;
        console.log(error.response.data);
      }
    },
    async checkMobile() {
      this.availableMobile = null;
      if (this.isMobile) {
        try {
          const response = await axios.post("user/IsMobileExist", {
            Mobile: this.mobilePayload.formattedNumber,
          });
          this.availableMobile = response.data;
        } catch (error) {
          this.availableMobile = false;
          this.errorMessage = error.response.data;
          console.log(error.response.data);
        }
      }
    },
    setMobilePayload(event) {
      if(!event.phoneNumber) return false
      const mobileNumber = parsePhoneNumber(event.phoneNumber.toString(), event.countryCode);
      if(mobileNumber) {
        const payload = {
          ...mobileNumber,
          isValid: mobileNumber ? mobileNumber.isValid() : false,
          countryCallingCode: mobileNumber.countryCallingCode,
          formatInternational: mobileNumber.formatInternational(),
          formatNational: mobileNumber.number,
          formattedNumber: mobileNumber.number,
          uri: mobileNumber.getURI(),
          e164: mobileNumber.ext
        }
        this.mobilePayload = payload;
      }
    },

    selectAccountType() {
      this.$store.dispatch("accountType", this.accountType);
      this.$router.push({ name: "RegisterPage" });
    },
    checkStoreType() {
      if (this.signInType === "SIGN_IN" || "") {
        this.signInPage = true;
      } else if (this.signInType === "REGISTER") {
        this.signInPage = false;
      }
    },
    onCloseModal() {
      this.signInPage = true;
      this.$store.dispatch("setSignInModalType", "SIGN_IN");
    },
  },
  watch: {
    isMobile() {
      this.checkMobile();
    },
  },
  mounted() {
    this.keepLogin();
  },
  created() {
    window.addEventListener("keydown", (e) => {
      if (e.key && this.mobileID) {
        this.focusTo();
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener("keydown", (e) => {
      if (e.key && this.mobileID) {
        this.focusTo();
      }
    });
  },
};
</script>

<style scope lang="scss">
@import "@/assets/sass/custom.scss";

// .modal-backdrop {
//    background-color: $blue-black !important;
//    opacity: 1 !important;
// }

.signIn_cover {
  background-color: $primary-darker;
  opacity: 0.9;
  height: 100%;
}

.fixed-background {
  height: 100%;
}

.mini-font {
  font-size: 0.7rem;
  color: $gray-600;
}

.input-pills {
  border-radius: 50px !important;
}

.left-rounded {
  border-radius: 50px 0px 0px 50px !important;
}

.right-rounded {
  border-radius: 0px 100px 100px 0px !important;
}

.p-left {
  padding-left: 15px !important;
  padding-right: 0 !important;
}

.p-right {
  padding-left: 0 !important;
  padding-right: 15px !important;
}

@media (max-width: 991px) {
  .p-left,
  .p-right {
    padding: 0 1rem !important;
  }
}
.action-buttons {
  label {
    &:hover {
      color: white !important;
    }
  }
}
</style>

<style lang="scss">
.signInModal .modal-open,
.signInModal .modal-body {
  padding: 0 !important;
}
.input-tel__input {
  outline: none !important;
}
.modal {
  .close {
    position: absolute;
    right: 25px;
    top: 10px;
  }
}
</style>
