<template>
  <div id="app">
    <div class="text-center" v-if="loading">
      <TheMainLoader />
    </div>

    <v-app v-else>
      <v-main>
        <div :style="{ visibility: loading ? 'hidden' : 'visible' }">
          <TheAutoLogout v-if="logState" />
          <TheHeader />
          <div class="mainContent">
            <keep-alive include="SearchResults">
              <router-view />
            </keep-alive>
          </div>
          <TheFooter />
          <TheSocket />
        </div>
      </v-main>
    </v-app>
  </div>
</template>
<script>
import TheHeader from "@/components/global/TheHeader";
import TheSocket from "@/components/global/TheSocket";
import TheFooter from "@/components/global/TheMainFooter";
import TheAutoLogout from "@/components/global/TheAutoLogout";
import TheMainLoader from "@/components/global/TheMainLoader";
import { mapGetters } from "vuex";
import { loadLanguageAsync } from "@/i18n";
import axios from "axios";
import moment from 'moment'
export default {
  name: "App",
  data() {
    return {
      loading: true,
      translationLoaded: false,
    };
  },
  watch:{
    "$route": function (){
      if(document && document.title && this.translationLoaded) document.title = this.$t(`PageTitle.${this.$route.name}`)
    }
  },
  components: {
    TheHeader,
    TheSocket,
    TheFooter,
    TheAutoLogout,
    TheMainLoader,
  },
  async created() {
    window.popStateDetected = false
    window.addEventListener('popstate', () => {
      window.popStateDetected = true
    })

    await this.$store.dispatch("resourceVersionFromApi");
    await this.$store.dispatch("languagesFromApi");
    this.translationLoaded = true
    document.title = this.$t(`PageTitle.${this.$route.name}`)
    let token = localStorage.getItem("token");
    let locale = localStorage.getItem("locale") || "en";
    if (token) {
      this.$store.dispatch("userFromApi", token);
      this.$store.dispatch("tokenID", token);
    }
    if (locale) {
      loadLanguageAsync(locale);
      await this.$store.dispatch("countriesFromApi", locale);
      await this.$store.dispatch("currenciesFromApi", locale);
      moment.locale(locale)
    }
    let c = localStorage.getItem("country");
    if (c) {
      this.$store.dispatch("setCountry", c);
    }

    let currency = localStorage.getItem("currency");
    if (currency) this.$store.dispatch("setCurrency", currency);

    await this.$store.dispatch("getCurrentCountry", {locale: this.$i18n.locale, forceChangeCurrency: false});

    this.loading = false;
  },
  computed: {
    ...mapGetters(["user"]),
    logState() {
      return Boolean(
        (this.user || localStorage.getItem("token")) && !localStorage.getItem("keepLogin")
      );
    },
  },
  mounted() {
    window.addEventListener("click", (event) => {
      // ensure we use the link, in case the click has been received by a subelement
      let { target } = event;
      while (target && target.tagName !== "A") target = target.parentNode;
      // handle only links that do not reference external resources
      if (target && target.matches("a:not([href*='://'])") && target.href) {
        // some sanity checks taken from vue-router:
        // https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
        const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } = event;
        // don't handle with control keys
        if (metaKey || altKey || ctrlKey || shiftKey) return;
        // don't handle when preventDefault called
        if (defaultPrevented) return;
        // don't handle right clicks
        if (button !== undefined && button !== 0) return;
        // don't handle if `target="_blank"`
        if (target && target.getAttribute) {
          const linkTarget = target.getAttribute("target");
          if (/\b_blank\b/i.test(linkTarget)) return;
        }
        // don't handle same page links/anchors
        const url = new URL(target.href);
        const to = url.pathname;
        if (window.location.pathname !== to && event.preventDefault) {
          event.preventDefault();
          this.$router.push(to);
        }
      }
    });
  },
  methods: {
    cleanBeforeClose() {
      if (!localStorage.getItem("keepLogin")) {
        window.onbeforeunload = function () {
          window.localStorage.unloadTime = JSON.stringify(new Date());
        };

        window.onload = function () {
          let loadTime = new Date();
          let unloadTime = new Date(JSON.parse(window.localStorage.unloadTime));
          let refreshTime = loadTime.getTime() - unloadTime.getTime();

          if (refreshTime > 10000) {
            //10 seconds
            window.location.assign("/");
            localStorage.clear();
            this.$store.dispatch("user", null);
            this.sendToken();
            this.showMsgBox();
          }
        };
      }
    },
    showMsgBox() {
      this.$bvModal
        .msgBoxConfirm("You have been logout.", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          cancelVariant: "success",
          okTitle: "Confirmed",
          cancelTitle: "Re-login",
          footerClass: "p-2",
          bodyClass: "p-4",
          hideHeaderClose: true,
          centered: true,
          noCloseOnBackdrop: true,
          noCloseOnEsc: true,
        })
        .then(value => {
          if (value) {
            window.location.assign("/");
          } else {
            this.$bvModal.show("signin");
          }
        });
    },
    async sendToken() {
      await axios
        .post(
          "SP/post/sp_Logout",
          {
            TokenId: localStorage.getItem("token"),
          },
          { timeout: 3000 }
        )
        .catch((error) => {
          console.log(error);
        });
    },
  },
  beforeMount() {
    document.addEventListener("beforeunload", this.cleanBeforeClose());
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600&display=swap');

a {
  text-decoration: none !important;
}
body,.v-application {
  font-family: 'Inter', sans-serif !important;
  background-color: #F8F9FA !important;
}
h1,h2,h3,h4,h5,h6,.poppins, [class*="btn"],button,[class~='title'],[class~='Title']{
  font-family: 'Poppins', sans-serif !important;
}
.v-application ul.navbar-nav, .v-application ul.dropdown-menu {
  padding-left: 0;
}
.flag.f-cm {
  background-position: -6px -1968px !important;
}
.flag.f-ci {
  background-position: -6px -1845px !important;
}
.flag.f-sn {
  background-position: -6px -8693px !important;
}
</style>
