<template>
  <b-modal
    modal-class="beSeller-modal"
    id="beSellerModal"
    centered
    title="PashMotors"
    hide-footer
    :hide-header-close="!isAccept"
    :no-close-on-esc="!isAccept"
    :no-close-on-backdrop="!isAccept"
    @hidden="handleClose"
  >
    <template v-if="!isAccept">
      <div class="ask-modal-header">
        {{$t("dashboard.label.doYouWantToBeASeller")}}
      </div>
      <div class="ask-modal-body">
        <div class="ask-modal-btn">
          <b-button variant="primary" @click="addSellerRole()">{{$t("global.button.yes")}}</b-button>
          <b-button variant="outline-primary" @click="closeSelf(true)">{{$t("global.button.no")}}</b-button>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="justify-center provide-mobile">
        <div>
          <label class="provide-mobile-label">
            {{$t("Reservation.PleaseProvideMobileNumber")}}
          </label>
          <TheMobileValidation limit-to-country="CA" :onValidate="addSellerRole()" />
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import axios from "axios";
import {mapGetters} from "vuex";
import TheMobileValidation from "@/components/global/TheMobileValidation.vue";
import parsePhoneNumber from "libphonenumber-js";

export default {
  components: {
    TheMobileValidation
  },

  data(){
    return {
      isAccept: false,
      isActive: false
    }
  },
  computed:{
    ...mapGetters(["user"]),
  },
  methods: {
    async addSellerRole(){
      try {
        if (!this.user.IsMobileVerified) {
          this.isAccept = true
        } else {
          const phoneNumber = parsePhoneNumber(this.user.Contact.Mobile);
          if(!phoneNumber.isValid() || phoneNumber.country !== 'CA'){
            this.isAccept = true
            return false
          }
          await axios.get("user/AddSellerRole");
          const response = await axios.get("user/GetCurrentUser")
          await this.$store.dispatch("user", response.data);
          this.isActive = true;
          this.$nextTick(() => {
            this.closeSelf(false);
            this.$router.push({name: 'listingOption'})
          })
        }
      }catch (e){
        console.log(e)
      }
    },
    handleClose(){
      if(!this.isActive){
        this.closeSelf(true)
      }
    },
    closeSelf(redirectToHome){
      this.$bvModal.hide("beSellerModal");
      if (redirectToHome) this.$router.push("/");
      this.isAccept = false;
    }
  },
};
</script>
<style lang="scss">
.ask-modal {
  .modal-header {
    position: relative;
    height: 40px;
    background: #006ac3;
    color: white;
    padding: 0 24px;
    display: flex;
    align-items: center;
    h5 {
      font-size: 20px;
      font-family: "Inter", sans-serif !important;
      font-weight: 600;
    }
    .close {
      padding: 0;
      position: absolute;
      top: 16px;
      font-weight: 300;
      opacity: 1;
      color: white;
      width: 30px;
      height: 100%;
    }
  }
  &-body {
    padding: 10px;
  }
  &-header {
    padding: 10px;
    font-size: 18px;
  }
  &-btn{
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    column-gap: 10px;
  }
  .icon {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: #ededed;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
  }
  .icon-title {
    font-size: 12px;
    color: #363636;
  }
  .ask-via {
    font-size: 16px;
    text-align: center;
    margin-bottom: 24px;
  }
  &-title {
    font-size: 18px;
    color: #111111;
    font-weight: 500;
  }
  &-subtitle {
    font-size: 14px;
    color: #404040;
  }
  .modal-body {
    padding: 0;
  }
}
</style>
