<template>
  <div class="bg-primary-dark" id="topBar">
    <b-container class="p-0 h-100">
      <b-navbar toggleable="lg" type="dark" class="container-fluid py-0 h-100">
        <b-navbar-nav class="text-center text-lg-left navbar-left">
          <small v-if="!showLogo">
            <span class="topbar-warning"> {{ $t("topbar.label.buySell") }}</span>
            <!-- <a href="" class="learn-more"
              >learn more <img src="@/assets/img/icons/arrow-right.svg" alt=""
            /></a> -->
            <router-link
              :to="{ path: '/en/about-pash' }"
              class="text-white ml-2 d-inline-block"
              exact
              exact-active-class="active"
            >
              <!--
              {{ $t("topbar.label.learnMore") }}
              <img
                class="ml-1"
                src="@/assets/img/icons/arrow-right-white.svg"
                alt="arrow-right-white"
              />
-->
            </router-link>
          </small>
          <b-navbar-brand v-else>
            <router-link :to="{ name: 'Home' }" class="navbar-brand">
              <img src="@/assets/img/Pash-logo.svg" alt="pash" title="logo" />
            </router-link>
          </b-navbar-brand>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <div class="ml-auto d-flex align-items-stretch justify-content-center h-100">
          <div
            class="px-2 contact"
            :class="{
              ' active ': $route.path === '/contact/contact',
            }"
          >
            <router-link
              :to="{ name: 'Contact', params: { type: 'contact' } }"
              :class="{
                ' contact_link_active ': $route.path === '/contact/contact',
              }"
              class="text-nowrap contact_link h-100"
            >
              <!-- <img
                class="mb-1"
                src="@/assets/img/icons/world-contact-icon.svg"
                alt="world-contact-icon"
              /> -->
              <img
                src="@/assets/img/icons/call-active.svg"
                alt=""
                v-if="$route.path === '/contact/contact'"
              />
              <img src="@/assets/img/icons/call.svg" v-else alt="" />
              {{ $t("topbar.label.contactUs") }}
            </router-link>
          </div>
          <TheLanguagePicker />
        </div>
      </b-navbar>
    </b-container>
    <!-- mobile menu -->
    <!-- <b-container fluid class="px-0 d-block d-md-none">
      <TheMobileMenu />
    </b-container> -->
  </div>
</template>

<script>
import TheLanguagePicker from "@/components/global/TheLanguagePicker.vue";
// import TheMobileMenu from "@/components/global/TheMobileMenu.vue";

export default {
  components: {
    TheLanguagePicker,
    // TheMobileMenu,
  },
  props: {
    isTransparent: {
      required: true,
      type: Boolean,
      default: false,
    },
    showLogo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      qSetLocale: "en",
      locales: [
        { value: "en", text: "English" },
        { value: "fr", text: "Francais" },
      ],
      qSetCountry: "CA",
      country: [
        { value: "CA", text: "Canada" },
        { value: "US", text: "USA" },
      ],
      qSetCurrency: "CAD",
      currency: [
        { value: "CAD", text: "Canadian Dollar" },
        { value: "USD", text: "U.S. Dollar" },
        { value: "MXN", text: "Mexican Peso" },
        { value: "EUR", text: "Euro" },
      ],
    };
  },
  computed: {
    isDashboardPage() {
      if (this.$route.matched[0]) {
        if (this.$route.matched[0].path === "/dashboards") {
          return true;
        } else return false;
      }
      return false;
    },
  },
  methods: {
    setLocale(evt) {
      let locale = evt.target.value;
      this.$i18n.locale = locale;
      localStorage.setItem("locale", locale);
    },
  },
};
</script>

<style scoped lang="scss">
.darkBG {
  background: rgba(0, 40, 155, 0.5);
}
.border-2 {
  border-width: 2px !important;
}
.side_distance {
  padding-left: 90px;
  padding-right: 100px;
  max-width: 1500px;
}
.contact {
  @media (max-width: 575px) {
    font-size: 12px;
  }
}
.contact_link {
  color: white !important;
  text-decoration: none;
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.contact_link_active,
.contact_link:hover {
  color: #fedf01 !important;
  text-decoration: none;
}

.active,
.contact:hover {
  border-bottom: 2px solid #fedf01 !important;
  color: #fedf01 !important;
}

@media (max-width: 991px) {
  .navbar-left {
    display: none;
  }
}

@media (max-width: 576px) {
  // .darkBG {
  //   background-color: $primary;
  // }
  .side_distance {
    padding: 0;
    margin-top: 10px;
    margin-bottom: 5px;
  }
}
#topBar {
  height: 40px;
  @media (max-width: 575px) {
    height: 30px;
  }
}
.topbar-warning {
  font-size: 12px;
  color: #fedf01;
}
.learn-more {
  font-size: 12px;
  color: white !important;
  text-transform: capitalize;
  margin: 0 1px;
}
</style>
