import axios from "axios";

export default {
  state: {
    pageContent: null
  },
  getters: {
    pageContent(state) {
      return state.pageContent;
    },
    buyPageContent(state) {
      let content = null;
      state.pageContent.forEach(element => {
        if (element.Name === "FrontEnd.Buy") {
          content = element.Resources;
        }
      });
      return content;
    },
    sellPageContent(state) {
      let content = null;
      state.pageContent.forEach(element => {
        if (element.Name === "FrontEnd.Sell") {
          content = element.Resources;
        }
      });
      return content;
    }
  },
  actions: {
    async pageContentFromApi({ commit }) {
      try {
        const response = await axios.post("Basic/Resource", {});
        let text = response.data;
        commit("pageContent", text);
      } catch (error) {
        commit("error", error);
      }
    }
  },
  mutations: {
    pageContent(state, value) {
      state.pageContent = value;
    }
  }
};
