var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    buySell: _vm.isBuySell,
    'sticky-position':
      _vm.$route.path.includes('inspection-agents') ||
      _vm.$route.path.includes('freight-forwarders') ||
      _vm.$route.path.includes('customs-brokers') ||
      _vm.$route.path.includes('sales-representatives') ||
      _vm.$route.path.includes('jobs'),
  },attrs:{"id":"header"}},[_c('TheTopBar',{attrs:{"isTransparent":_vm.isBuySell,"showLogo":_vm.needShowLogo}}),_c('TheNavBar',{attrs:{"isTransparent":_vm.isBuySell}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }